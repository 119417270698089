<!-- Header -->
<!-- Buscador -->
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <input #input (keyup)="buscarUsuarios( input.value )" type="text" class="form-control" placeholder="Buscar Usuarios">
            </div>
        </div>
    </div>
</div>

<!-- Loading -->

<div class="row animated fadeIn" *ngIf="cargando">
    <div class="col-sm-12">
        <div class="alert alert-warning text-center">
            <strong> Cargando</strong>
            <br>
            <i class="fa fa-refresh fa-spin fa-2x"></i>
        </div>
    </div>
</div>
<!-- Final Buscador -->

<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div #table class="card-body">
                <div class="text-right d-none d-md-block">
                    <button [disabled]="totalRegistros >= maxusuarios" [routerLink]="['/usuario','nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-primary"><i class="mdi mdi-plus"></i>
                    Crear Usuario
                    </button>
                </div>

                <h3 class="card-title"><i class="mdi mdi-account"></i> Usuarios registrados ( <small>{{ nusuarios }}</small> ) de un total de ( <small>{{ maxusuarios }}</small> )</h3>
                <table class="table table-hover" #table [ngClass]="{'table-responsive' : table.clientWidth < 500}">
                    <thead>
                        <tr>
                            <th class="priority-2">Nombre</th>
                            <th class="priority-3">Usuario</th>
                            <th class="priority-4">Módulos</th>
                            <th class="priority-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usuario of usuarios">
                            <td class="priority-2">
                                {{ usuario.nombre }}
                            </td>
                            <td class="priority-3">
                                {{ usuario.usuario }}
                            </td>
                            <td class="priority-4">
                                <span *ngFor="let i of usuario.Options"><span  *ngIf="i.Activo === 'True' " class="badge badge-pill badge-info">{{i.Titulo}}</span></span>
                            </td>
                            <td class="priority-1">
                                <button [routerLink]="['/usuario',usuario.id]" class="btn btn-primary">
                                    <i class="mdi mdi-lead-pencil "></i>
                                </button>
                                <button [disabled]="usuario.rol === 'ADMIN'" (click)="borrarUsuario(usuario)" class="btn btn-danger ">
                                    <i class="mdi mdi-delete"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="totalpaginas > 1" aria-label="Page navigation" class="dataTables_wrapper">
                    <ul class="pagination justify-content-center">
                        <li>
                            <button class="btn btn-outline-secondary" [ngClass]="{'disabled' : pagina <= 1}" (click)="cambiarPagina(-1)">Anterior</button>
                        </li>
                        <li *ngFor="let i of itemPagina"><button [ngClass]="{'active' : pagina === i}" class="btn btn-outline-secondary" (click)="cargarUsuarios(i)">{{i}}</button></li>
                        <li>
                            <button class="btn btn-outline-secondary" [ngClass]="{'disabled' : pagina >= totalpaginas}" (click)="cambiarPagina(+1)">Siguiente</button>
                        </li>
                    </ul>
                </div>
                <!-- FAB button -->
                <div class="d-block d-md-none">
                    <button class="fab btn waves-effect waves-light btn-rounded btn-primary" [disabled]="totalRegistros >= maxusuarios" [routerLink]="['/usuario','nuevo']">
                    <i style="color: white" class="mdi mdi-plus"></i>
                </button>
                </div>
            </div>
        </div>
    </div>
</div>