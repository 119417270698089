// Modelo, plantillas de nuestro usuarios
// El orden de las propiedades es inportante.
// Deben coincidir el nombre y valor de las propiedades con el backend

export class PreciosFormulario {

    constructor(
        public estaciones?: any[],
        public precio?: number,
        public carburante?: object,
    ){

    }
}
