<div class="row animated fadeIn">
    <div class="col-lg-7">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Información del usuario</h4>
                <h6 class="card-subtitle">Puedes modificar tu información básica desde aquí</h6>

                <form ngNativeValidate [formGroup]="forma" (ngSubmit)="guardar()" class="form p-t-20">
                    <div class="form-group">
                        <label for="exampleInputuname">Nombre</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="mdi mdi-account"></i></div>
                            <input formControlName="nombre" name="nombre" type="text" class="form-control" [placeholder]="usuario.nombre" required>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="mdi mdi-email"></i></div>
                            <input formControlName="mail" name="mail" type="email" class="form-control" [placeholder]="usuario.usuario" required>
                        </div>
                    </div>
                    <div class="dropdown-divider"></div>
                    <!-- Implementación Modificacion de contraseñas -->
                    <div class="form-group">
                        <label for="exampleInputEmail1">Nueva constraseña</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="mdi mdi-key"></i></div>
                            <input formControlName="password" name="password" class="form-control" type="password" placeholder="Nueva constraseña">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Repetir nueva contraseña</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="mdi mdi-key-change"></i></div>
                            <input formControlName="password2" name="password2" class="form-control" type="password" placeholder="Repetir nueva contraseña">
                        </div>
                    </div>
                    <div class="form-group" *ngIf="forma.errors?.sonIguales && !forma.pristine">
                        <div class="col-xs-12">
                            <div class="alert alert-danger text-center" role="alert">
                                <i class="mdi mdi-exclamation"></i>Las contraseñas no coinciden<i class="mdi mdi-exclamation"></i>
                            </div>
                        </div>
                    </div>
                    <!-- Fin Implementación de contraseñas -->

                    <button [disabled]="forma.errors?.sonIguales && !forma.pristine" type="submit" class="btn btn-block btn-success waves-effect waves-light m-r-10"><i class="mdi mdi-content-save"></i> Guardar</button>
                </form>
            </div>
        </div>
    </div>
</div>