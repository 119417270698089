<!-- Header -->
<!-- Buscador -->
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div #alert class="card-body">
                <div class="alert alert-info alert-dismissible fade show alert-rounded" role="alert" *ngIf="alert.clientWidth > 500">
                    <i class="fa fa-exclamation-circle text-info"></i> Escanea o escribe un código para: <b>Crear</b>, <b>Modificar</b> o <b>Borrar</b> un artículo. <br> Recuerda usar (<b> * </b>) para <b>Actualizar</b> y (<b> / </b>) para <b>Sumar</b>                    si haces el inventario desde un <b>PC</b>.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="row">
                    <div class="col-6 ribbon-wrapper">
                        <div class="ribbon ribbon-bookmark  ribbon-info">
                            Nº &nbsp; {{inventario.identificador}}
                        </div>
                        <div class="mt-3">
                            <h6 class="card-subtitle">{{inventario.fecha_inventario}} - {{inventario.desc_almacen}}</h6>
                        </div>
                        <div>
                            <h6 class="card-subtitle">Registros cargados: {{cargaInventario}} de {{inventario.nregistros}} </h6>
                            <button *ngIf="!cargandoMas" (click)="cargarMas(idInventario)" [disabled]="cargaInventario === totalRegistrosInventario" type="button" class="btn btn-info waves-effect waves-light"><i class="mdi mdi-cloud-download"></i> Cargar todo</button>
                            <button *ngIf="cargandoMas" type="button" class="disabled btn btn-info waves-effect waves-light"><i class="fa fa-refresh fa-spin"></i> Cargar todo</button>
                        </div>
                    </div>
                    <div class="text-right col-6 d-none d-md-block">
                        <button [routerLink]="['/inventarios']" type="button" class="btn waves-effect waves-light btn-rounded btn-danger"><i class="mdi mdi-arrow-left"></i>
                         Regresar
                         </button>
                    </div>
                </div>
                <div class="mt-3 mb-3 input-group">
                    <input [(ngModel)]="input" name="input" (keyup)="buscarArticulos( input.value, $event.keyCode )" type="text" class="form-control" placeholder="Buscar Artículos" appAutofocus [focuMe]="isFocus">
                    <div class="input-group-append">
                        <span name="selectedItems" (click)="borrarValue()" class="input-group-addon" type="button"><i class="mdi mdi-backspace waves-effect waves-light"></i></span>
                    </div>
                </div>
                <!-- Loading -->
                <div class="row animated fadeIn mt-3" *ngIf="cargando">
                    <div class="col-sm-12">
                        <div class="alert alert-warning text-center">
                            <strong>Cargando</strong>
                            <br>
                            <i class="fa fa-refresh fa-spin fa-2x"></i>
                        </div>
                    </div>
                </div>
                <!-- Loading END-->
                <table #table class="table table-hover" [ngClass]="{'table-responsive' : table.clientWidth < 500}" *ngIf="inventario.Lineas !== undefined">
                    <thead>
                        <tr>
                            <th>Artículo</th>
                            <th>Descripción</th>
                            <th>Stock Inicial</th>
                            <th>Stock</th>
                            <th>Fecha</th>
                            <!-- <th></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let linea of inventario.Lineas">
                            <td>
                                {{linea.articulo}}
                            </td>
                            <td>
                                {{linea.descripcion}}
                            </td>
                            <td>
                                {{linea.stock_inicial}}
                            </td>
                            <td>
                                {{linea.stock}}
                            </td>
                            <td>
                                {{linea.fecha_calculo}}
                            </td>
                            <!--  <td>
                                 <button (click)="buscarArticulos(linea.articulo, 13); modificarModal();" class="btn btn-primary">
                                <i class="fa fa-edit "></i>
                            </button>
                            </td>-->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- FAB button -->
        <div class="d-block d-md-none">
            <button class="fab btn waves-effect waves-light btn-rounded btn-danger" [routerLink]="['/inventarios']">
        <i style="color: white" class="mdi mdi-arrow-left"></i>
    </button>
        </div>
    </div>
</div>

<div class="modal animated fadeIn show" tabindex="-1" role="dialog" style="display: block;" *ngIf="modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div>
                    <div>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="modificarModal()">
                    <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="ribbon ribbon-vertical-l  ribbon-warning" *ngIf="linea.tipo_peticion === 'update'">
                        <span class="text-dark"><i class="mdi mdi-alert"></i></span>
                    </div>
                </div>
                <h3 class="m-b-20 text-center"><b>Informe cantidad del producto</b></h3>
                <h5 class="m-b-20 text-center">{{linea.descripcion }}</h5>
                <h5 class="m-b-20 text-center">{{linea.cbarras}}</h5>
                <h4 class="m-b-20 text-center">{{linea.fecha_calculo}}</h4>
                <h4 class="m-b-20 text-center"><i class="mdi mdi-cube-outline"></i> Stock Inicial: {{linea.stock_inicial}}&nbsp;&nbsp;&nbsp;<span><i class="mdi mdi-cube"></i> <b>Stock: {{linea.stock}}</b></span></h4><br>
                <form>
                    <input (keyup)="modificarArticulosTeclado(input.value, $event.keyCode)" [(ngModel)]="stockLinea" name="linea.stock" type="number" class="form-control" id="recipient-name" appAutofocus [focuMe]="isFocus" required>
                    <span *ngIf="linea.tipo_peticion === 'update'" class="text-warning"><i class="mdi mdi-alert"></i><small> Este artículo ya existe en el inventario actual</small></span><br>
                    <span *ngIf="stockLinea === null" class="text-danger"><i class="mdi mdi-exclamation"></i><small> Tiene que informar una cantidad</small></span>
                </form>
            </div>
            <div class="modal-footer d-flex">
                <button (click)="borrarArticulo(linea.idlinea)" class="btn btn-danger waves-effect mr-auto">
                        <i class="fa fa-trash-o "></i>
                    </button>
                <button [disabled]="stockLinea === null" type="button" class="btn btn-info waves-effect waves-light " (click)="modificarArticulos('A')" data-toggle="tooltip" title="Puedes pulsar: /"><i class="mdi mdi-plus"></i>Sumar</button>
                <button [disabled]="stockLinea === null" type="button" class="btn btn-primary waves-effect waves-light " (click)="modificarArticulos('C')" data-toggle="tooltip" title="Puedes pulsar: *"><i class="mdi mdi-swap-horizontal"></i>Actualizar</button>
            </div>
        </div>
    </div>
</div>
