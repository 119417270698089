import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { InventarioService } from '../../services/inventario/inventario.service';
import { Almacen } from '../../models/almacen.model';
import { Inventario } from '../../models/inventario.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventarios',
  templateUrl: './inventarios.component.html',
  styleUrls: ['./inventarios.component.css']
})
export class InventariosComponent implements OnInit {

  almacenes: Almacen[] = [];
  inventarios: Inventario[] = [];

  almacenSeleccionado = [];
  dropdownSettings = {};

  cargando: boolean = true;

  constructor( public inventarioService: InventarioService, public router: Router) { }

  ngOnInit(): void {

    this.cargarAlmacenes();

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'almacen',
      textField: 'descripcion',
      selectAllText: 'Seleccionar todos',
      unSelectAllText: 'Deseleccionar todos',
      itemsShowLimit: 30,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar...',
      noDataAvailablePlaceholderText: 'No hay estaciones disponibles',
      closeDropDownOnSelection: true
    };

    if (this.inventarioService.almacenSeleccionado !== null) {
      this.almacenSeleccionado = this.inventarioService.almacenSeleccionado;
      this.cargarInventarios(this.almacenSeleccionado[0].almacen);
    }
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.almacenSeleccionado);
    const xAlmacen: any = this.almacenSeleccionado[0].almacen;
    this.inventarioService.guardarStorage(this.almacenSeleccionado);
    this.cargarInventarios(xAlmacen);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  cargarAlmacenes(){
    this.cargando = true;

    this.inventarioService.cargarAlmacenes()
    .subscribe( (resp: any) => {
      console.log(resp);
      this.almacenes = resp.Estacion;
      this.cargando = false;
    });

  }

  cargarInventarios(xAlmacen: Almacen){
    this.cargando = true;

    this.inventarioService.cargarInventarios(xAlmacen)
    .subscribe( (resp: any) => {
      console.log(resp);
      this.inventarios = resp.Inventario;
      console.log(this.inventarios);
      this.cargando = false;
    });

  }

  crearInventario() {
    // this.cargando = true;

    const xIdAlmacen: any = { };

    xIdAlmacen.almacen = this.almacenSeleccionado[0].almacen;

    this.inventarioService.crearInventario(xIdAlmacen)
    .subscribe( (resp: any) => {
      console.log(resp);
      this.almacenes = resp.Estacion;
      // this.cargando = false;
      this.router.navigate(['/inventario/', resp.datos.identificador]);
    });
  }

}
