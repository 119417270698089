import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { Usuario } from '../../models/usuario.model';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';

declare function init_plugins();

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  usuario: Usuario;

  // Formulario, sirve para conectar el TS con el HTML
  forma: FormGroup;

  constructor(public usuarioService: UsuarioService) {
    this.usuario = this.usuarioService.usuario;
  }

    // Función VALIDATOR para saber si dos campos són iguales
    sonIguales( campo1: string, campo2: string) {

      return ( group: FormGroup) => {

        const pass1 = group.controls[campo1].value;
        const pass2 = group.controls[campo2].value;

        if (pass1 === pass2) {
          return null;
        } else {
        return {
          sonIguales: true
        };
      }
     };
   }

  ngOnInit(): void {

    init_plugins();

    this.forma = new FormGroup({

      nombre: new FormControl(null, Validators.required),
      mail: new FormControl({value: this.usuario.usuario, disabled: true}, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      password2: new FormControl(null, Validators.required),
      condiciones: new FormControl(false),

    }, { validators: this.sonIguales( 'password', 'password2')});
  }

  guardar( ){

    const xUsuario = this.forma.value;

    console.log('xUsuario1');
    console.log(xUsuario);

    if (xUsuario.password == null) {
      delete xUsuario.password;
      delete xUsuario.password2;
    }

    console.log('xUsuario2');
    console.log(xUsuario);

    xUsuario.id = this.usuario.id;
    xUsuario.mail = this.usuario.mail;

    this.usuarioService.modificaPerfil(xUsuario)
                       .subscribe((resp: any) => {
                       console.log(resp);
                      });


}
}


