import { Component, OnInit, EventEmitter, HostListener, ViewChild, ViewChildren } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { InventarioService } from '../../services/inventario/inventario.service';
import { Almacen } from '../../models/almacen.model';
import { Inventario } from '../../models/inventario.model';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario/usuario.service';


@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css']
})

export class InventarioComponent implements OnInit {

  cargando: boolean = true;

  inventario: Inventario = new Inventario(0, '', 0, '', 0, ['']);

  input: any = '';

  modal: boolean = false;
  isFocus: boolean = true;

  linea: any = '';
  stockLinea: number = null;

  key: number = 0;

  idInventario: number  = 0;

 // Inventario (Cargar mas)
  cargaInventario: number = 20;

  cargandoMas: boolean = false;
  totalRegistrosInventario: number = null;

  constructor(
    public usuarioService: UsuarioService,
    public inventarioService: InventarioService,
    public router: Router,
    public activatedroute: ActivatedRoute
    ) {
    this.activatedroute.params.subscribe( resp => {
      const id = resp['id'];

      // Guardamos para el cargar mas
      this.idInventario = id;

      if ( id !== 'nuevo') {
        this.obtenerInventario(id);
        console.log('usuariow---1--');
        console.log(this.inventario);
      }else{
        console.log(this.inventarioService.inventario);
      }
    });

  }

  ngOnInit(): void {
  }


  buscarArticulos(xTermino: string, xKey: any){

    console.log(xKey);
    this.key = xKey;
    console.log('this.key:' + this.key);

    if (!xTermino) {
      xTermino = this.input;
    }

    if (xTermino.length <= 0){
      return this.obtenerInventario(this.inventario.identificador);

      }

    if (xTermino >= '' && this.key === 13) {

      console.log(xTermino);
      console.log('this.key');
      console.log(this.key);

      const xArticulo: any = { };

      xArticulo.idinventario = this.inventario.identificador;
      xArticulo.almacen = this.inventario.almacen;
      xArticulo.cbarras = xTermino;

      console.log('XArticulo');
      console.log(xArticulo);

      this.inventarioService.buscarArticulos(xArticulo)
                      .subscribe( (resp: any) => {
                        console.log('flag88' + JSON.stringify(resp));
                        this.modal = true;
                        this.linea = resp.datos;
                      });

      this.input = '';

    }


  }

  borrarArticulo(xLineaId: number){
    console.log(xLineaId);
    this.inventarioService.borrarArticulo(xLineaId)
                          .subscribe((resp: any) => {
                             this.modificarModal();
    });

  }

  modificarArticulosTeclado(xTermino: number, xKey: number){


    if (!xTermino) {
      xTermino = this.stockLinea;
    }

    /* if (xTermino <= 0){
      return this.obtenerInventario(this.inventario.identificador);

      } */

    console.log('xKey:' + xKey);
    console.log('xTermino:' + this.stockLinea);


     if (xTermino && xKey === 106) {

      this.modificarArticulos('C');

    }

    if (xTermino && xKey === 111) {

      console.log(xTermino);
      console.log(xKey);

      this.modificarArticulos('A');

    }

  }

  modificarArticulos(xTipo: string) {


    // Renovamos el token cada vez que modifica producto
    this.actualizaToken();

    const xArticuloModificado: any = { };

    xArticuloModificado.idlinea = this.linea.idlinea;
    xArticuloModificado.stock = this.stockLinea;
    xArticuloModificado.tipo = xTipo;

    console.log(xArticuloModificado);

    this.inventarioService.modificarArticulos(xArticuloModificado)
                          .subscribe((resp: any) => {
                            Swal.fire({
                              icon: 'success',
                              html: 'La cantidad de <b> ' + resp.datos.descripcion + '</b> <br> ahora es: <b>' + resp.datos.stock + '</b>',
                              showConfirmButton: false,
                              timer: 2300
                            });
                            this.modificarModal();
                            });

  }

  obtenerInventario(xId: number){
    this.cargando = true;
    this.cargaInventario = 20;

    this.inventarioService.obtenerInventario(xId, `${this.cargaInventario}/1`)
    .subscribe( (resp: any) => {
      console.log(resp);
      this.inventario = resp;
      this.totalRegistrosInventario = this.inventario.nregistros;
      this.cargando = false;
    });

  }

  borrarValue(){

    this.input = '';

  }

  actualizaToken(){
    this.usuarioService.renuevaToken()
      .subscribe( (resp: any) => {
    });
    console.log('Token al actualitzar');
  }

  modificarModal(){

    this.modal = !this.modal;
    this.linea = {};
    this.stockLinea = null;
    this.obtenerInventario(this.inventario.identificador);

  }

  cargarMas(xId: number){
    this.cargaInventario = this.totalRegistrosInventario;
    this.cargandoMas = true;
    this.cargando = true;

    console.log('cargando todo');

    this.inventarioService.obtenerInventario(xId, `${this.cargaInventario}/1`)
    .subscribe( (resp: any) => {
      console.log(resp);
      this.inventario = resp;
      this.cargando = false;
      this.cargandoMas = false;
    });
  }



}
