import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuarioService } from '../../services/usuario/usuario.service';
import Swal from 'sweetalert2';
import { ModalUploadService } from '../../components/modal-upload/modal-upload.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  usuarios: Usuario[] = [];
  totalpaginas: number = 0;
  itemPagina: number[] = [];
  pagina: number = 1;

  totalRegistros: number = 0;
  nusuarios: number = 0;
  maxusuarios: number = 0;

  cargando: boolean = true;
  fab: boolean = false;

  constructor( public usuarioService: UsuarioService,
               public modalUploadService: ModalUploadService
    ) { }

  ngOnInit(): void {
    this.cargarUsuarios();
  }

  // ===============================================
  // xxxxxxxxxxxx TEST 2 xxxxxxxxxxxxxxxxxxxxxxxxxxx
  // ===============================================

  cargarUsuarios(xPagina = 1){

    this.cargando = true;

    this.usuarioService.cargarUsuarios( this.usuarioService.usuario, xPagina)
                        .subscribe( (resp: any) => {
        console.log('respCargarUsuarios');
        console.log(resp);
        this.totalRegistros = resp.totalregistros;
        this.nusuarios = resp.nusuarios;
        this.maxusuarios = resp.maxusuarios;
        this.usuarios = resp.UsuarioWeb;
        this.totalpaginas = resp.totalpaginas;
        this.cargando = false;
        this.itemPagina = Array.from({length: this.totalpaginas}, (v, k) => k + 1);
        this.pagina = xPagina;
        console.log(xPagina);
      });
    }

  // ===============================================
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  // ===============================================

    cambiarPagina( valor: number){

      const pagina = this.pagina + valor;
      console.log(pagina);
      console.log(this.totalpaginas);

      if (pagina > this.totalpaginas) {
        return;
      }

      if (pagina < 0) {
        return;
      }

      this.pagina = pagina;
      this.cargarUsuarios(this.pagina);

    }

    buscarUsuarios( termino: string){

      if (termino.length <= 0){
      return this.cargarUsuarios();

      }

      this.pagina = 0;
      this.totalpaginas = 0;

      this.cargando = true;

      this.usuarioService.buscarUsuarios(termino)
                         .subscribe( (resp: any)  => {
                          this.usuarios = resp;
                          this.cargando = false;
                         });
      console.log( 'TB' + this.totalpaginas);
    }

    borrarUsuario( xUsauario: Usuario ){

      this.usuarioService.borrarUsuario( xUsauario.id)
      .subscribe( () => this.cargarUsuarios());

      }

// ====================================
// Botón de guardar
// ====================================
    guardarUsuario(usuario: Usuario){

    }

// ====================================
// Botón de FAB
// ====================================
fabChange(){
  this.fab = !this.fab;
  console.log('change fab');
  console.log(this.fab);
}

  }

