import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-incrementador',
  templateUrl: './incrementador.component.html',
  styles: [
  ],
})
export class IncrementadorComponent implements OnInit {

   // Propiedades locales de la clase incrementador
  @ViewChild('inputProgress') inputProgress: ElementRef;


  @Input() leyenda: string = 'Leyenda';
  @Input() porcentaje: number = 80;

  // Variable que nos sirve para actualizar valor que tiene una variable.
  @Output() cambioPorcentaje: EventEmitter <number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  // Emite el valor del ngModel quando cambia de valor en el HMTL. (INPUT)
  onChange( xNewValue: number ) {

    if (xNewValue >= 100 ) {
      this.porcentaje = 100;
    }else if (xNewValue <= 0) {
      this.porcentaje = 0;
    }else {
      this.porcentaje = xNewValue;
    }


    // Limitamos la introduccion de número de 0 al 100 con ViewChild
    this.inputProgress.nativeElement.value = this.porcentaje;

    // Emitimos el valor que tiene porcentaje en este momento
    // Para que sea actualizado en el componente hijo
    this.cambioPorcentaje.emit( this.porcentaje );

  }

    // Métodos locales de la clase progress

  cambiarValor = (xValor: number) => {

    if ( this.porcentaje >= 100 && xValor > 0 ) {
        // Si el valor es mas grande que 100 y valor sea positivo pon 100
        this.porcentaje = 100;
        return;
    }

    if ( this.porcentaje <= 0 && xValor < 0 ) {
        // Si el valor es mas pequeño que 100 y valor sea negativo pon 100
        this.porcentaje = 0;
        return;
    }

    this.porcentaje = this.porcentaje + xValor;

    // Emitimos el valor que tiene porcentaje en este momento
    // Para que sea actualizado en el componente hijo
    this.cambioPorcentaje.emit( this.porcentaje);

    // Ponemos el focus en el número al pulsar el botón
    this.inputProgress.nativeElement.focus();

    }

}
