import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {

  // Propiedades locales de la clase progress

  porcentaje1: number = 20;
  porcentaje2: number = 80;

  // -----------------------------------------

  constructor( ) {

  }

  ngOnInit(): void {
  }

}
