<div class="row">

    <div class="col-sm-12">
        <div class="card ">
            <div class="card-body">

                <div class="col-6 ribbon-wrapper" *ngIf="loadingGrafica1">
                    <div class="ribbon ribbon-bookmark  ribbon-info">
                        Gráfica total ventas anuales [Euros-Meses]
                    </div>
                </div>

                <!-- Loading -->
                <div class="row animated fadeIn mt-3" *ngIf="!loadingGrafica1">
                    <div class="col-sm-12">
                        <div class="alert alert-warning text-center">
                            <strong>Cargando</strong>
                            <br>
                            <i class="fa fa-refresh fa-spin fa-2x"></i>
                        </div>
                    </div>
                </div>
                <!-- Loading END-->

                <!-- Grafica 1 -->
                <div *ngIf="loadingGrafica1">
                    <div>
                        <app-grafico-line [lineChartLabels]="graficaGeneral.labels" [lineChartData]="graficaGeneral.datasets" [lineChartType]="graficaGeneral.type"></app-grafico-line>
                    </div>
                </div>
                <!-- Grafica END-->

            </div>
        </div>
    </div>

</div>

<div class="row">

    <div class="col-sm-12">
        <div class="card ">
            <div class="card-body">

                <div class="col-6 ribbon-wrapper">
                    <div class="ribbon ribbon-bookmark  ribbon-info">
                        Gráfica periodo carburantes por estación
                    </div>
                </div>

                <div class="row d-flex justify-content-end mr-2">
                    <div class="col-lg-4 col-md-12 col-sm-12 mb-2 input-group">
                        <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                        <input [(ngModel)]="fechaInicial2" name="fecha" type="date" class="form-control" placeholder="dd/mm/yyyy" (keydown)="$event.preventDefault();">
                    </div>


                    <div class="col-lg-4 col-md-12 col-sm-12 mb-2 input-group">
                        <span class="input-group-addon"><i class="mdi mdi-calendar-multiple"></i></span>
                        <input [(ngModel)]="fechaFinal2" name="fecha" type="date" class="form-control" placeholder="dd/mm/yyyy" (keydown)="$event.preventDefault();">
                    </div>

                    <button (click)="obtenerGraficaVentaPeriodoCarburante()" [ngClass]="{'disabled' : fechaInicial2 > fechaFinal2 || fechaInicial2 == undefined || fechaFinal2 == undefined  }" type="button" class=" ml-2 mb-2 btn waves-effect waves-light btn-rounded btn-primary d-none d-md-inline">
                        <i class="mdi mdi-magnify"></i>
                    </button>

                </div>



                <!-- Loading -->
                <div class="row animated fadeIn mt-3" *ngIf="loadingGrafica2">
                    <div class="col-sm-12">
                        <div class="alert alert-warning text-center">
                            <strong>Cargando</strong>
                            <br>
                            <i class="fa fa-refresh fa-spin fa-2x"></i>
                        </div>
                    </div>
                </div>
                <!-- Loading END-->

                <!-- Sin fecha -->
                <div class="row animated fadeIn mt-3" *ngIf="InfoGrafica2">
                    <div class="col-sm-12">
                        <div class="alert alert-info text-center">
                            <i class="fa fa-exclamation-circle text-info"></i> Selecciona un intervalo de <b>fechas</b> para <b>crear</b> el gráfico.
                            <br>
                            <i class="mdi mdi-chart-bar mdi-36px"></i>
                        </div>
                    </div>
                </div>
                <!-- Sin fecha END-->

                <!-- Grafica 1 -->
                <div *ngIf="!InfoGrafica2">
                    <div>
                        <app-grafico-line [lineChartLabels]="graficaGeneral2.labels" [lineChartData]="graficaGeneral2.datasets" [lineChartType]="graficaGeneral2.type"></app-grafico-line>
                    </div>
                </div>
                <!-- Grafica END-->

            </div>
        </div>
    </div>

</div>