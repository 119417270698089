import { OnInit, ElementRef, Input, Directive } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective {

  // tslint:disable-next-line: no-input-rename
  @Input('focuMe') isFocused: boolean;

  constructor(private hostElement: ElementRef) { }

// tslint:disable-next-line: use-lifecycle-interface
ngDoCheck() {

if (this.isFocused) {
  this.hostElement.nativeElement.focus();
}

}

}

