// Importamos el Módulo de rutas y el modelo que necesitamos
// para nuestro arreglo

import { RouterModule, Routes } from '@angular/router';

// Importamos las diferentes paginas TS de nuestra aplciación. las clases

// Tenemos todo el HTML que no es el login en el PagesComponent
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { ResultadosComponent } from './resultados/resultados.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RxjsComponent } from './rxjs/rxjs.component';
import { ProfileComponent } from './profile/profile.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuarioComponent } from './usuarios/usuario.component';
import { ModificaPreciosComponent } from './modificaPrecios/modifica-precios.component';

// GARDS
import { LoginGuardGuard } from '../services/guards/login-guard.guard';
import { AdminGuard } from '../services/guards/admin.guard';
import { InventariosComponent } from './inventarios/inventarios.component';
import { InventarioComponent } from './inventarios/inventario.component';
import { VerificaTokenGuard } from '../services/guards/verifica-token.guard';
import { SurtidoresComponent } from './surtidores-component/surtidores.component';




const pagesRoutes: Routes = [

    /*Tenemos dos router-outlet, uno para el login y otro para las otras páginas*/

    { path: '', component: PagesComponent,
    // Vinculación de las rutas con el archivo Guards
    canActivate: [ LoginGuardGuard ],
    // Rutas hijas cuando no ponemos nada en la url.
    children: [
        { path: 'dashboard', component: DashboardComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'Dashboard'}},
        { path: 'progress', component: ProgressComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'Progress'}},
        { path: 'resultados', component: ResultadosComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'Resultados'}},
        { path: 'promesas', component: PromesasComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'Promesas'}},
        { path: 'rxjs', component: RxjsComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'RxJs'}},
        { path: 'account-settings', component: AccountSettingsComponent , canActivate: [ VerificaTokenGuard ], data: {titulo: 'Ajustes del tema'}},
        { path: 'perfil', component: ProfileComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'Perfil de usuario'}},
        { path: 'inventarios', component: InventariosComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'Inventarios'}},
        { path: 'inventario/:id', component: InventarioComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'Inventario'}},
        { path: 'modificaPrecios/:id', component: ModificaPreciosComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'Modificar precios estacions'}},
        // tslint:disable-next-line: max-line-length
        { path: 'surtidores', component: SurtidoresComponent, canActivate: [ VerificaTokenGuard ], data: {titulo: 'Estado de los surtidores'}},


        // Mantenimientos
        {
            path: 'usuarios',
            component: UsuariosComponent,
            canActivate: [ AdminGuard, VerificaTokenGuard ],
            data: {titulo: 'Mantenimiento de usuarios'}
        },
        { path: 'usuario/:id', component: UsuarioComponent, data: {titulo: 'Ficha de usuario'}},
        // Si la url està vacia, nos lleva al dashboard
        { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
    ]},
];

// Creamos una constante que usaremos para exportar nuestras rutass
// Le decimos al RouterModule que lea el array que acabamos de crear
// y que los transforme en rutas que el navegador entienda.
// También le decimos que use el hash # en nuestras rutas.

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
