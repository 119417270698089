import { Component, Input, OnInit, ViewChild, Output } from '@angular/core';
import { ChartType, ChartDataSets  } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';

@Component({
  selector: 'app-grafico-line',
  templateUrl: './grafico-line.component.html',
  styleUrls: ['./grafico-line.component.css']
})
export class GraficoLineComponent implements OnInit {

    // Line lineChartLegend
    @Input() public lineChartLabels: Label[];
    @Input() public lineChartData: ChartDataSets[];
    @Input() public lineChartType: ChartType;

  constructor() { }

  ngOnInit(): void {
  }

}
