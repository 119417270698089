import { Component, OnInit } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';


@Component({
  selector: 'app-surtidores-component',
  templateUrl: './surtidores.component.html',
  styleUrls: ['./surtidores.component.css']
})
export class SurtidoresComponent implements OnInit {
  [x: string]: any;

  // VARIABLES


  // Template
  cargando: boolean = true;
  fab: boolean = false;
  notificaciones: boolean = true;

  constructor(public usuarioService: UsuarioService, public http: HttpClient) { }

  ngOnInit(): void {

    // Notificaciones
    this.notificarme();

  }

  // Notificaciones: Preguntar i enviar notificaciones

  /* enviarNotificacion() {

    const notificationOpts = {
      body: 'Esta es una notificación de Cixteam',
      icon: '../assets/images/icons/icon-150x150_b.png',
    };

    new Notification('Hay un error en el surtidor', notificationOpts);

  } */

  notificarme() {
    // Miramos si el navegador soporta notificaiones
    if ( !window.Notification) {
      console.log('Este navegador no soporta notificaciones');
      return;
    }

    if ( Notification.permission === 'granted') {
        this.notificaciones = false;
    } else {
      Notification.requestPermission( ( permission ) => {

          console.log(permission);

          if ( permission === 'granted') {
            new Notification('Se han activado las notificaciones de CixPanel');
            this.notificaciones = false;
          }
    });
  }
}

}
