import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class VerificaTokenGuard implements CanActivate {

  constructor(
    public usuarioService: UsuarioService,
    public router: Router
  ){}
  canActivate(): Promise<boolean> | boolean {

    const token = this.usuarioService.token;

    const payload = JSON.parse( atob( token.split('.')[1]));

    const expirado = this.expirado(payload.exp);

    console.log('Flag: Verifica Token');
    console.log(payload);
    console.log(this.usuarioService.token);

    if ( expirado ) {
      console.log('token expirado');
      this.router.navigate(['/login']);
      return false;
    }
    return this.verificaRenueva( payload.exp );
  }

  verificaRenueva( fechaExp: number ): Promise<boolean> {

    return new Promise( (resolve, reject) => {

      const tokenExp = new Date( fechaExp * 1000);
      const ahora = new Date();

      ahora.setTime( ahora.getTime() + ( 4 * 60 * 60 * 1000));

      console.log( tokenExp );
      console.log( ahora );
      console.log('PASO expirado');

      if ( tokenExp.getTime() > ahora.getTime()) {
        resolve (true);
      } else {
        this.usuarioService.renuevaToken()
                          .subscribe( () => {
                            resolve(true);
                          }, () => {
                            console.log('Al login que vas!!!! Guard');
                            this.router.navigate(['/blad1']);

                            reject(false);
                          });
      }

    });
  }


  expirado( fechaExp: number ){

    const ahora = new Date().getTime() / 1000;

    if ( fechaExp < ahora){
      console.log('PASO expirado');
      return true;
    } else {
      console.log('token expirado');
      return false;
    }

  }
}
