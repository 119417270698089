<h3>
    {{leyenda}}
</h3>
<div class="input-group ">
    <span class="input-group-btn ">
        <button (click)="cambiarValor(-5)" class="btn btn-primary " type="button ">
            <i class="mdi mdi-minus "></i>
        </button>
    </span>
    <input #inputProgress type="number" class="form-control" [(ngModel)]="porcentaje" (ngModelChange)="onChange($event)" min="0" max="100">
    <span class="input-group-btn ">
        <button (click)="cambiarValor(+5)" class="btn btn-primary " type="button ">
            <i class="mdi mdi-plus "></i>
        </button>
    </span>
</div>