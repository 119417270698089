import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { Usuario } from 'src/app/models/usuario.model';
import { SWorkerAndNotificationService } from '../../services/sWorkerAndNotification/s-worker-and-notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  usuario: Usuario;

  constructor( public usuarioService: UsuarioService, public sWorkerAndNotificationService: SWorkerAndNotificationService) { }

  ngOnInit(): void {
    this.usuario = this.usuarioService.usuario;
  }

  notificaciones(){
      console.log('Notificaciones a tope');
      this.sWorkerAndNotificationService.campanaNotificaciones();
  }

}
