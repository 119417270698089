<div class="row animated fadeIn">
    <div class="col-lg-12">
        <div class="card">
            <div #alert class="card-body">
                <div class="alert alert-info alert-dismissible fade show alert-rounded" role="alert" *ngIf="alert.clientWidth > 500">
                    <i class="fa fa-exclamation-circle text-info"></i> Selecciona una de las <b>estaciones</b> para <b>crear</b> o <b>ver</b> un inventario.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- Implementación de selector de opciones -->
                <h4><i class="mdi mdi-home-map-marker card-title"></i> Estación</h4>
                <ng-multiselect-dropdown [(ngModel)]="almacenSeleccionado" name="selectedItems" [placeholder]="'Selecciona una estación...'" [settings]="dropdownSettings" [data]="almacenes" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
                <!-- Fin Implementación de selector de opciones -->
                <!-- Loading -->

                <div class="row animated fadeIn mt-3" *ngIf="cargando">
                    <div class="col-sm-12">
                        <div class="alert alert-warning text-center">
                            <strong>Cargando</strong>
                            <br>
                            <i class="fa fa-refresh fa-spin fa-2x"></i>
                        </div>
                    </div>
                </div>
                <!-- Final Buscador -->
                <div class="mt-5 animated fadeIn" *ngIf="almacenSeleccionado.length !== 0">
                    <div class="text-right d-none d-md-block">
                        <button (click)="crearInventario()" type="button" class="btn waves-effect waves-light btn-rounded btn-primary"><i class="mdi mdi-plus"></i>
                    Crear Inventario
                    </button>
                    </div>
                    <!-- Loading -->

                    <div class="row animated fadeIn mt-3" *ngIf="inventarios === undefined">
                        <div class="col-sm-12">
                            <div class="alert alert-danger text-center">
                                <strong>Este almacén no tiene inventarios disponibles.</strong>
                                <br>
                                <i class="mdi mdi-radio-tower fa-2x"></i>
                            </div>
                        </div>
                    </div>
                    <table class="table table-hover" *ngIf="inventarios !== undefined" #table [ngClass]="{'table-responsive' : table.clientWidth < 500}">
                        <thead>
                            <tr>
                                <th>Identificador</th>
                                <th>Fecha</th>
                                <th>Total registros</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let inventario of inventarios">
                                <td>
                                    {{inventario.identificador}}
                                </td>
                                <td>
                                    {{inventario.fecha_inventario}}
                                </td>
                                <td>
                                    {{inventario.nregistros}}
                                </td>
                                <td>
                                    <button [routerLink]="['/inventario',inventario.identificador]" class="btn btn-primary">
                                    <i class="mdi mdi-lead-pencil"></i>
                                </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- FAB button -->
        <div class="d-block d-md-none" *ngIf="almacenSeleccionado.length !== 0">
            <button class="fab btn waves-effect waves-light btn-rounded btn-primary" (click)="crearInventario()">
        <i style="color: white" class="mdi mdi-plus"></i>
            </button>
        </div>
    </div>
</div>