// Modelo, plantillas de nuestro usuarios
// El orden de las propiedades es inportante.
// Deben coincidir el nombre y valor de las propiedades con el backend

export class Usuario {

    constructor(
        public mail?: string,
        public password?: string,
        public nombre?: string,
        public usuario?: string,
        public cliente?: number,
        public nusuarios?: number,
        public rol?: string,
        public id?: string,
        public Options?: any[ ],
        public menu?: any,
        public url?: string,
        public bbdd?: string,
        public exp?: number
    ){

    }
}

