import { Component, OnInit } from '@angular/core';
import { InventarioService } from '../../services/inventario/inventario.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { ModificaPreciosService } from '../../services/modificaPrecios/modifica-precios.service';
import { Inventario } from '../../models/inventario.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
  ],
})
export class DashboardComponent implements OnInit {


  // Opciones a pintar

  urlPinarCards = [];

  inventarios: Inventario[] = [];
  almacenSeleccionado = null;
  descripcion = null;

  cargando: boolean = true;

  // Historial
  historial = [];

  constructor(
    public usuarioService: UsuarioService,
    public inventarioService: InventarioService,
    public modificaPreciosService: ModificaPreciosService,
    public router: Router,
  ) {
    this.pintarCards();
  }

  ngOnInit(): void {

    // Miramos si tiene almacen guardado en el local storage
    if (this.inventarioService.almacenSeleccionado !== null) {
      this.almacenSeleccionado = this.inventarioService.almacenSeleccionado;
      this.descripcion = this.almacenSeleccionado[0].descripcion;
      this.cargarInventarios(this.almacenSeleccionado[0].almacen);
    }

    // Obtenemos el Historial si lo tiene activado

    if (this.urlPinarCards.includes('/modificaPrecios/nuevo')) {
      this.obtenerHistorial();
    }

    }

  pintarCards(){

    const urlOpciones = this.usuarioService.usuario.menu.menu[0].submenu;

    const num: number = urlOpciones.length;
    let y: number = 0;

    for (y; y < num; y++) {
          this.urlPinarCards.push(urlOpciones[y].url);
          console.log('urlPinarCards');
          console.log(this.urlPinarCards);
    }

  }


  cargarInventarios(xAlmacen){

      this.cargando = true;

      this.inventarioService.cargarInventarios(xAlmacen, '/5/1/')
      .subscribe( (resp: any) => {
        console.log(resp);
        this.inventarios = resp.Inventario;
        console.log(this.inventarios);
        this.cargando = false;
      });

  }

  obtenerHistorial(){

    this.modificaPreciosService.obtenerHistorial('5/1')
      .subscribe( (resp: any) => {

        this.historial = resp.CambioPrecio;
        console.log(this.historial);

    });

  }

}
