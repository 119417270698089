<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><img src="../assets/images/users/{{usuario.cliente}}/foto.png" alt="user" /><span class="hide-menu">{{usuario.nombre}}</span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a routerLinkActive="active" routerLink="/perfil" class="nav-toggler">Mi Perfil </a></li>
                        <li><a routerLinkActive="active" routerLink="/account-settings" class="nav-toggler">Configuración</a></li>
                        <li><a (click)="usuarioService.logout()" class="pointer">Salir</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>

                <!-- Cargamos las opciones del menú -->

                <li *ngFor="let menu of sidebarService.menu">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><i class="{{menu.icono}}"></i><span class="hide-menu">{{menu.titulo}} <span class="label label-rouded label-themecolor pull-right">{{menu.submenu.length}}</span></span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li *ngFor="let sub of menu.submenu">
                            <a routerLinkActive="active" [routerLink]="[sub.url]" class="nav-toggler">{{sub.titulo}}</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->