// Módulos del pages
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {DpDatePickerModule} from 'ng2-date-picker';


// Módulos propios
import { SharedModule } from '../shared/shared.module';

// Componentes del pages
import { PagesComponent } from './pages.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { ResultadosComponent } from './resultados/resultados.component';
import { ProfileComponent } from './profile/profile.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { HospitalesComponent } from './hospitales/hospitales.component';
import { UsuarioComponent } from './usuarios/usuario.component';
import { InventariosComponent } from './inventarios/inventarios.component';
import { ModificaPreciosComponent } from './modificaPrecios/modifica-precios.component';
import { SurtidoresComponent } from './surtidores-component/surtidores.component';

// RUTAS
import { PAGES_ROUTES } from './pages.routes';

// TEMPORAL
import { IncrementadorComponent } from '../components/incrementador/incrementador.component';
import { GraficoDonaComponent } from '../components/grafico-dona/grafico-dona.component';
import { GraficoLineComponent } from '../components/grafico-line/grafico-line.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RxjsComponent } from './rxjs/rxjs.component';

// Pipes module
import { PipesModule } from '../pipes/pipes.module';
import { InventarioComponent } from './inventarios/inventario.component';

// Directivas
import { AutofocusDirective } from '../directives/autofocus.directive';




@NgModule({
  declarations: [
    PagesComponent,
    DashboardComponent,
    ProgressComponent,
    ResultadosComponent,
    IncrementadorComponent,
    GraficoDonaComponent,
    GraficoLineComponent,
    AccountSettingsComponent,
    PromesasComponent,
    RxjsComponent,
    ProfileComponent,
    UsuariosComponent,
    HospitalesComponent,
    UsuarioComponent,
    InventariosComponent,
    InventarioComponent,
    AutofocusDirective,
    ModificaPreciosComponent,
    SurtidoresComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PAGES_ROUTES,
    FormsModule,
    ChartsModule,
    PipesModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    DpDatePickerModule,
    ChartsModule
  ],
  providers: [
  ],
  exports: [
      // Exportamos los componentes para que puedan ser usadas
      // fuera de este módulo
    DashboardComponent,
    ProgressComponent,
    GraficoLineComponent
  ]
})

// Nombre de la clase de nuestro módulo
export class PagesModule { }
