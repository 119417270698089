import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioService } from '../usuario/usuario.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError, repeat } from 'rxjs/operators';
import { URL_CONSULTAS } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class ResultadosService {

  constructor(public usuarioService: UsuarioService, public http: HttpClient) { }

// ====================================
// Pintar Grafica General
// ====================================

cargarGraficaGeneral() {

  let url = `${URL_CONSULTAS}/GraficaGeneral`;
  url += '?token=' + this.usuarioService.token;

  console.log('URL: CONSULTAS');
  console.log(url);

  return this.http.get(url).pipe(
    catchError( err => {
      this.usuarioService.gestionaError(err);
      return throwError(err);
  }));
}

graficaVentaPeriodoCarburante(fechaInicio: any, fechaFin: any){
  let url = `${URL_CONSULTAS}/GraficaVentas/Carburantes/${fechaInicio}/${fechaFin}`;
  url += '?token=' + this.usuarioService.token;

  console.log('URL: CONSULTAS');
  console.log(url);

  return this.http.get(url).pipe(
    catchError( err => {
      this.usuarioService.gestionaError(err);
      return throwError(err);
  }));
}
}
