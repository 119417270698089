import { Component, OnInit } from '@angular/core';
import { ModificaPreciosService } from '../../services/modificaPrecios/modifica-precios.service';
import { Carburante } from '../../models/carburante.model';
import { PreciosFormulario } from 'src/app/models/preciosFormulario';
import { InventarioService } from '../../services/inventario/inventario.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modifica-precios',
  templateUrl: './modifica-precios.component.html',
  styleUrls: ['./modifica-precios.component.css']
})
export class ModificaPreciosComponent implements OnInit {

  modal: boolean = false;
  modal2: boolean = false;

  // Cargando
  cargando: boolean = true;

  // FAB
  fab: boolean = false;

  // Drop down multi select
  dropdownList = [];
  Options = [];
  dropdownSettings = {};

  // Data piker (Viejo)
  selectedDate = undefined;
  datePickerConfig = {};

  // Data piker (Nuevo)
  fechaSeleccionada = undefined;
  horaSeleccionada = undefined;

  // Formulario
  precio: number = null;

   // Historial
   historial = [];
   historiaSeleccionada: any = {};
   idHistorial: string = null;
   cargaHistorial: number = 50;

   cargandoMas: boolean = false;
   totalRegistrosHistorial: number = null;

  // VARIABLES

  carburantes = [];
  estaciones = [];
  preciosTabla = [];
  carburanteSeleccionado: Carburante = new Carburante(' ', ' ', ' ', ' ', ' ');
  preciosFormulario: PreciosFormulario = new PreciosFormulario([ ], 0, {});

  constructor(
    public modificaPreciosService: ModificaPreciosService,
    public inventarioService: InventarioService,
    public router: Router,
    public activatedroute: ActivatedRoute
  ) {
    this.activatedroute.params.subscribe( resp => {

      const id = resp.id;

      this.idHistorial = id;

      if ( id !== 'nuevo') {
          this.abrirHistoria(id);
          console.log('Passa por el ID con numero');
          console.log(id);
      }else{
          console.log('Passa ID');
          this.obtenerCarburantes();
      }
    });
  }

  ngOnInit(): void {

  this.dropdownSettings = {
    singleSelection: false,
    idField: 'codigo',
    textField: 'descripcion',
    selectAllText: 'Seleccionar todas',
    unSelectAllText: 'Deseleccionar todos',
    itemsShowLimit: 30,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar...',
    noDataAvailablePlaceholderText: 'No hay módulos disponibles'
  };

// Data piker

  this.datePickerConfig = {

  firstDayOfWeek: 'mo',
  disableKeypress: true,
  allowMultiSelect: false,
  closeOnSelect: undefined,
  closeOnSelectDelay: 100,
  onOpenDelay: 0,
  weekDayFormat: 'dd',
  appendTo: document.body,
  drops: 'down',
  opens: 'right',
  showNearMonthDays: true,
  showWeekNumbers: false,
  enableMonthSelector: true,
  format: 'DD-MM-YYYY HH:mm',
  yearFormat: 'YYYY',
  showGoToCurrent: true,
  dayBtnFormat: 'DD',
  monthBtnFormat: 'MMM',
  hours12Format: 'hh',
  hours24Format: 'HH',
  meridiemFormat: 'A',
  minutesFormat: 'mm',
  minutesInterval: 1,
  secondsFormat: 'ss',
  secondsInterval: 1,
  showSeconds: false,
  showTwentyFourHours: true,
  timeSeparator: ':',
  multipleYearsNavigateBy: 10,
  showMultipleYearsNavigation: false,
  locale: 'es',
  // min: this.selectedDate._i,
  // minTime: this.selectedDate._i
};

}


onItemSelect(item: any) {
console.log(item);
console.log(this.Options);
}

 onSelectAll(items: any) {
console.log(items);
}

  modificarModal(){

    this.modal = !this.modal;
    this.Options = [];
    this.precio = null;

  }

  modificarModal2(){

    this.modal2 = !this.modal2;
    this.cargaHistorial = 50;

  }


  obtenerCarburantes(){

    this.cargando = true;

    this.modificaPreciosService.obtenerCarburantes()
    .subscribe( (resp: any) => {
      console.log(resp);
      this.carburantes = resp.Carburante;
      console.log('carburantes');
      console.log(this.carburantes);
      this.obtenerEstaciones();
    });

  }

  obtenerEstaciones(){

    const xTipo = 'tipo=\'ES\'';

    this.inventarioService.cargarAlmacenes(xTipo)
    .subscribe( (resp: any) => {
      console.log(resp);
      this.estaciones = resp.Estacion;
      this.dropdownList = this.estaciones;
      console.log('estaciones');
      console.log(this.estaciones);
      console.log('dropdownList');
      console.log(this.dropdownList);


      // Creamos los precios
      this.crearObjetoPrecios();
      this.cargando = false;
    });

  }

  modificarCarburante(xCarburante: any){

    this.carburanteSeleccionado = xCarburante;

    this.modal = !this.modal;
    console.log (this.selectedDate);

  }

  crearObjetoPrecios(){
    const num: number = this.carburantes.length;
    let i: number = 0;

    let PFormulario: any = {};
    let molde: any = {};
    this.preciosTabla = [];

    for (i; i < num; i++) {
      PFormulario = new PreciosFormulario(JSON.parse(JSON.stringify(this.dropdownList)), 0, this.carburantes[i]);

      molde = PFormulario.carburante;
      molde.estaciones = PFormulario.estaciones;
      molde.precio = PFormulario.precio;

      console.log('Molde');
      console.log(molde);

      this.preciosTabla.push(molde);
      console.log(i);
    }
    console.log('this.preciosTabla');
    console.log(this.preciosTabla);

    console.log('this.Options');
    console.log(this.Options);
  }

  guardar( f: any, xDelete: boolean = false ){


    const estCarSelec = this.preciosTabla.find(x => x.codigo === this.carburanteSeleccionado.codigo);


    console.log('Formuarlio Valido ' + f.valid);

    f.value.carburante = this.carburanteSeleccionado;

    const estCarburanteSelec = this.preciosTabla.find(x => x.codigo === this.carburanteSeleccionado.codigo);

    // Poner todas las opciones a false
    const num2: number = this.estaciones.length;
    let y: number = 0;

    for (y; y < num2; y++) {
          estCarburanteSelec.estaciones[y].seleccionado = false;
          console.log(y);
        }

    // Asignar true a las estaciones selecionadas por el usuario

    // SI DELETE no ho fa
    if (xDelete === false){
    const num: number = this.Options.length;
    let i: number = 0;

    for (i; i < num; i++) {
      estCarburanteSelec.estaciones.find(x => x.codigo === this.Options[i].codigo).seleccionado = true;
      console.log(i);
      console.log('estCarburanteSelec');
      console.log(estCarburanteSelec);

    }
  }

    // Asignar precio al carburante seleccionado
    if (xDelete === false){
    this.preciosTabla.find(x => x.codigo === this.carburanteSeleccionado.codigo).precio = f.value.precio;
    } else {
      this.preciosTabla.find(x => x.codigo === this.carburanteSeleccionado.codigo).precio = 0;
    }

    console.log(this.preciosTabla);

    this.modal = !this.modal;
    this.Options = [];
    this.precio = null;

  }

  guardaryenviar( ){

    console.log('fecha');
    console.log(this.fechaSeleccionada);
    console.log(this.horaSeleccionada);

    const molde: any = {};
    let fecha: string;

    // Año
    const ano: any = this.fechaSeleccionada.substring(0, 4);
    console.log('ano');
    console.log(ano);

    // Mes
    const mes: any = this.fechaSeleccionada.substring(5, 7);
    console.log('mes');
    console.log(mes);

    // Dia
    const dia: any = this.fechaSeleccionada.substring(8, 10);
    console.log('dia');
    console.log(dia);

    // Hora
    const hora: any = this.horaSeleccionada.substring(0, 2);
    console.log('hora');
    console.log(hora);

    // Minuto
    const minuto: any = this.horaSeleccionada.substring(3, 5);
    console.log('minuto');
    console.log(minuto);

    // Crear fecha

    fecha = `${dia}/${mes}/${ano} ${hora}:${minuto}:00`;

    console.log('fecha');
    console.log(fecha);



    molde.fecha_aplicacion = fecha;
    molde.carburantes = this.preciosTabla.slice();

    console.log('molde');
    console.log(molde);

    this.modificaPreciosService.crearCambioPrecio(molde)
    .subscribe( (resp: any) => {
      console.log(resp);
      // Historial
      this.historiaSeleccionada = resp.datos;
      this.preciosTabla = resp.datos.carburantes;

      this.router.navigate(['/modificaPrecios', this.historiaSeleccionada.identificador]);

      Swal.fire(
        'Enviado',
        'Cambio de precio enviado correctamente',
        'success'
      );
  });

}

obtenerHistorial(){

  this.modificaPreciosService.obtenerHistorial(`${this.cargaHistorial}/1`)
    .subscribe( (resp: any) => {


      // Guaradmos el total registros como seguro para el botón cargar mas.
      this.totalRegistrosHistorial = resp.totalregistros;

      // Guardamos los datos del historial
      this.historial = resp.CambioPrecio;
      console.log(this.historial);
      this.modificarModal2();
  });

}

cargarMas(){
  this.cargaHistorial = this.cargaHistorial + 20;
  this.cargandoMas = true;
  this.modificaPreciosService.obtenerHistorial(`${this.cargaHistorial}/1`)
    .subscribe( (resp: any) => {

      this.historial = resp.CambioPrecio;
      console.log(this.historial);
      this.cargandoMas = false;
  });
}

abrirHistoria(xId: any){

    console.log('xId');
    console.log(xId);

    this.modificaPreciosService.abrirHistoria(xId)
    .subscribe( (resp: any) => {

      this.historiaSeleccionada = resp;
      console.log('historiaSeleccionada');
      console.log(this.historiaSeleccionada);

      this.preciosTabla = resp.carburantes;

      this.modal2 = false;
      this.cargando = false;
  });

}

borrarHistoria(){

  console.log('historiaSeleccionada');
  console.log(this.historiaSeleccionada);

  this.modificaPreciosService.borrarCambioPrecio(this.historiaSeleccionada.identificador)
    .subscribe( (resp: any) => {
      Swal.fire({
        title: 'Borrado',
        html: 'Cambio de precios <b> ' + resp.datos.id + '</b>' + ' borrado correctamente',
        showConfirmButton: true,
        icon: 'success'
      }).then((result) => {
        if (result.value) {
          this.reload();
        }
      });
  });

}

reload() {

  this.modal = false;
  this.modal2 = false;

  // Cargando
  this.cargando = true;

  // Drop down multi select
  this.dropdownList = [];
  this.Options = [];

  // Data piker
  this.selectedDate = undefined;

  // Data piker (Nuevo)
  this.fechaSeleccionada = undefined;
  this.horaSeleccionada = undefined;

  // Formulario
  this.precio = null;

  // Historial
  this.historial = [];
  this.historiaSeleccionada = {};

  // VARIABLES

  this.carburantes = [];
  this.estaciones = [];
  this.preciosTabla = [];
  this.carburanteSeleccionado = {};
  this.preciosFormulario = {};


  this.router.navigate(['/modificaPrecios', 'nuevo']);
}


// ====================================
// Botón de FAB
// ====================================
fabChange(){
  this.fab = !this.fab;
  console.log('change fab');
  console.log(this.fab);
}

}
