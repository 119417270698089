import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, repeat } from 'rxjs/operators';
import { UsuarioService } from '../usuario/usuario.service';
import { URL_NOTIFICACIONES } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class SWorkerAndNotificationService {

/*Notificaciones*/
 swReg: any;
 activadas: any;

  constructor( public usuarioService: UsuarioService, public http: HttpClient) { }


  // Mira si se puede, i si se puede instalar el SW
  InstalarServiceWorker(){
    if ( 'serviceWorker' in navigator) {
                          navigator.serviceWorker
                              .register('sw.js')
                              .then((registration) => {
                                  console.log('Service Worker registered');
                                  this.swReg = registration;
                                  this.swReg.pushManager.getSubscription().then(this.verificarSuscripcion);
                              })
                              .catch((err) => {
                                  console.log('Service Worker registration failed: ', err);
                              });
                      }
    }


// Notificaciones: Mirar si estàn activadas
verificarSuscripcion(activadas) {
          if (activadas) {
                          // Si están activadas
                  console.log('Estàs suscrito');
            } else {
                          // Si están desactivadas
                  console.log('No estàs suscrito');
           }
  }



// Obtener key pública

obtenerKey() {
  console.log('Obtener key');

  return fetch( URL_NOTIFICACIONES + '/key')
  .then( res => res.arrayBuffer())
  .then( key => new Uint8Array(key));
}

// Enviar subscipción al Backend

enviaSubscripcionBackend(xSubscripcion) {

  let url = URL_NOTIFICACIONES + '/subscribe';

  const subscripcionJSON =  JSON.stringify( xSubscripcion );

  console.log('xSubscripcion');
  console.log(xSubscripcion);

  fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(xSubscripcion)
  });

  /* return this.http.post( url, subscripcionJSON )
                  .pipe( map( (resp: any) => {
                    console.log('POST NOTIFICACIONES');
                    console.log(resp);
                    return resp;
                  }),
                  catchError( err => {
                    this.usuarioService.gestionaError(err);
                    return throwError(err);
                  })
                  );*/

}

// Al pulsar la campana de notificaciones
campanaNotificaciones() {
  console.log('paco1');
  // console.log(paco);
  if (!this.swReg){
    return console.log('No hay registro de SW');
  }

  this.obtenerKey().then( (key) => {

    this.swReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: key
    })
    .then( res => res.toJSON())
    .then( suscription => {
      console.log(suscription);

      this.verificarSuscripcion(suscription);

      this.enviaSubscripcionBackend(suscription);
      console.log('paco2');
      // console.log(paco);


    });

  });
}

}
