import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  // Valores por defecto
  ajustes: Ajustes = {
    temaURL: 'assets/css/colors/default.css',
    tema: 'default'
  };


  constructor( @Inject(DOCUMENT) private sDocument: Document ) {
    this.cargarAjustes();
   }

  guardarAjustes() {
    console.log('Guarado en el local storage');
    localStorage.setItem('ajustes', JSON.stringify(this.ajustes));
  }

  cargarAjustes() {

    if (localStorage.getItem('ajustes')) {
      this.ajustes = JSON.parse( localStorage.getItem('ajustes'));

      this.aplicarTema(this.ajustes.tema);

      console.log('Tema cargado');

    }else{
      console.log('Tema por defecto');
    }
  }

  aplicarTema( xTema: string ){

    const url: string = `assets/css/colors/${xTema}.css`;

    const temaHTML = this.sDocument.querySelector('#tema').setAttribute ('href', url);

    // Guardamos  el tema en el local storage, con el SettingsService
    this.ajustes.tema = xTema;
    this.ajustes.temaURL = url;

    this.guardarAjustes();

    return temaHTML;

  }
}


interface Ajustes {
  temaURL: string;
  tema: string;
}
