<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <h4 class="card-title"> Barra de progreso</h4>

                <div class="progress mb-3">
                    <div id="paco" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [attr.ariaValuenow]="porcentaje1" aria-valuemin="0" aria-valuemax="100" [style.width]="porcentaje1 + '%'"></div>
                </div>

                <div class="progress">
                    <div id="paco" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [attr.ariaValuenow]="porcentaje2" aria-valuemin="0" aria-valuemax="100" [style.width]="porcentaje2 + '%'"></div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="row ">
    <div class="col-6 ">
        <div class="card ">
            <div class="card-body ">
                <app-incrementador (cambioPorcentaje)="porcentaje1 = $event" leyenda="Procentaje1" [porcentaje]="porcentaje1"></app-incrementador>
            </div>
        </div>
    </div>


    <div class="col-6 ">
        <div class="card ">
            <div class="card-body ">

                <app-incrementador (cambioPorcentaje)="porcentaje2 = $event" leyenda="Procentaje2" [porcentaje]="porcentaje2"></app-incrementador>

            </div>
        </div>
    </div>
</div>