import { NgModule } from '@angular/core';

import {SettingsService} from './settings/settings.service';
import {SharedService} from './shared/shared.service';
import {SidebarService} from './shared/sidebar.service';
import { UsuarioService } from './usuario/usuario.service';
import { HttpClientModule } from '@angular/common/http';
import { LoginGuardGuard } from './guards/login-guard.guard';
import { ModalUploadService } from '../components/modal-upload/modal-upload.service';
import { AdminGuard } from './guards/admin.guard';
import { SWorkerAndNotificationService } from './sWorkerAndNotification/s-worker-and-notification.service';



@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    SettingsService,
    SharedService,
    SidebarService,
    UsuarioService,
    LoginGuardGuard,
    AdminGuard,
    SWorkerAndNotificationService
  ],
})

export class ServiceModule { }
