<!-- Header -->


<!-- Final Buscador -->

<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div #table class="card-body">
                <!-- Aviso Notificaciones -->
                <div *ngIf="notificaciones" class="alert alert-danger alert-dismissible fade show alert-rounded" role="alert">
                    <i class="mdi mdi-alert-outline text-warning"></i> <b> Permite</b> las <b>notificaciones  </b> <i class="mdi mdi-arrow-up-bold-circle"></i> para ser avisado de los errores <b>graves</b> en surtidores.
                    <button type="button" class=" ml-2 mb-2 btn waves-effect waves-light btn-secondary d-none d-md-inline">
                        Permitir notificaciones
                    </button>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- Loading -->
                <div class="row animated fadeIn" *ngIf="cargando">
                    <div class="col-sm-12">
                        <div class="alert alert-warning text-center">
                            <strong> Cargando</strong>
                            <br>
                            <i class="fa fa-refresh fa-spin fa-2x"></i>
                        </div>
                    </div>
                </div>
                <table class="table table-hover" #table [ngClass]="{'table-responsive' : table.clientWidth < 500}">
                    <thead>
                        <tr>
                            <th class="priority-2">Fecha</th>
                            <th class="priority-3">ID</th>
                            <th class="priority-4">Estación</th>
                            <th class="priority-1">Turno</th>
                            <th class="priority-1">S.</th>
                            <th class="priority-1">Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="">
                            <td class="priority-2">

                            </td>
                            <td class="priority-3">

                            </td>
                            <td class="priority-4">

                            </td>
                            <td class="priority-1">

                            </td>
                            <td class="priority-5">

                            </td>
                            <td class="priority-6">

                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- FAB button -->
                <div class="d-block d-md-none">
                    <button class="fab btn waves-effect waves-light btn-rounded btn-danger" [routerLink]="['/inventarios']">
                <i style="color: white" class="mdi mdi-arrow-left"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>