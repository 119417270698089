import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/usuario.model';
import { FormGroup } from '@angular/forms';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { Router, ActivatedRoute } from '@angular/router';

import { IDropdownSettings } from 'ng-multiselect-dropdown';

declare function init_plugins();

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

  usuario: Usuario;
  usuariow: Usuario = new Usuario('', '', '', '', 0, 0, '', '', ['paco', 'pepe']);

  dropdownList = this.usuarioService.usuario.Options;
  Options = [];
  dropdownSettings = {};

  fab: boolean = false;

  constructor(
    public usuarioService: UsuarioService,
    public router: Router,
    public activatedroute: ActivatedRoute
    ) {
    this.usuario = this.usuarioService.usuario;
    this.activatedroute.params.subscribe( resp => {
      const id = resp['id'];

      if ( id !== 'nuevo') {
        this.obtenerUsuario(id);
        console.log('usuariow---1--');
        console.log(this.usuariow);
      }else{
        console.log(this.usuarioService.usuario.Options);
      }
    });

  }

  ngOnInit(): void {

    init_plugins();
    console.log('Multi---2--');

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'modulo',
      textField: 'Titulo',
      selectAllText: 'Seleccionar todos',
      unSelectAllText: 'Deseleccionar todos',
      itemsShowLimit: 30,
      allowSearchFilter: true,
      searchPlaceholderText: 'Buscar...',
      noDataAvailablePlaceholderText: 'No hay módulos disponibles'
    };

}
   onItemSelect(item: any) {
  console.log(item);
  console.log(this.Options);
}
   onSelectAll(items: any) {
  console.log(items);
}

  filtrarOptions(option: any){
 /*Función para cojer solo las opciónes activas del usuario*/
        if (option.Activo === 'True'){
          return option;
        }
  }

  guardar( f: any ){


    console.log('Formuarlio Valido ' + f.valid);


    // Si el usuario que estamos modificando es admin, tenemos que añadir su mail de esta manera en el formulario
    // Al tener el campo en disabled no lo coje del HTML al hacer submit.
    if (this.usuariow.rol === 'ADMIN') {
      f.value.mail = this.usuariow.mail;
    }

    f.value.id = this.usuariow.id;
    console.log(f.value);
    this.usuarioService.crearUsuario(f.value)
                      .subscribe( (resp: any) => {
                        console.log('flag88' + JSON.stringify(resp));
                        this.router.navigate(['/usuario/', resp.datos.id]);
                      });

  }

  obtenerUsuario(xId: string) {
    this.usuarioService.obtenerUsuario(xId)
                        .subscribe( resp => {
                          this.usuariow = resp;
                          console.log('usuariow---1--');
                          console.log(this.usuariow);
                          this.dropdownList = this.usuariow.Options;
                          this.Options = this.usuariow.Options.filter(this.filtrarOptions);
                          console.log('options select---1--');
                          console.log(this.Options);
                        });
  }

// ====================================
// Botón de FAB
// ====================================
fabChange(){
  this.fab = !this.fab;
  console.log('change fab');
  console.log(this.fab);
}
}
