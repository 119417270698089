import { Injectable } from '@angular/core';
import { Inventario } from '../../models/inventario.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, repeat } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SubirArchivoService } from '../subir-archivo/subir-archivo.service';
import { Observable, throwError } from 'rxjs';
import { UsuarioService } from '../usuario/usuario.service';
import { Almacen } from '../../models/almacen.model';


@Injectable({
  providedIn: 'root'
})
export class InventarioService {

  almacen: number;
  inventario: Inventario;


  almacenSeleccionado: any;

  constructor(public usuarioService: UsuarioService, public http: HttpClient) {
    this.cargarStorage();
  }

// ====================================
// Pintar Almacenes
// ====================================

cargarAlmacenes(xTipo: string = '') {

  let url = this.usuarioService.url + '/Get_lista/ES/0/0/' + xTipo;
  url += '?token=' + this.usuarioService.token;

  return this.http.get(url).pipe(
    catchError( err => {
      this.usuarioService.gestionaError(err);
      return throwError(err);
  }));
}

// ====================================
// Pintar Inventarios de un Alamcén concreto
// ====================================

cargarInventarios(xAlmacen: Almacen, xNumero = '/0/0/') {
  let url = this.usuarioService.url + '/Get_lista/IF' + xNumero + 'almacen=' + xAlmacen;
  url += '?token=' + this.usuarioService.token;

  return this.http.get(url).pipe(
    map( (resp: any) => {
      console.log(resp);
      return resp;
    }),
    catchError( err => {
      this.usuarioService.gestionaError(err);
      return throwError(err);
  }));
}

// ====================================
// Pintar los Artículos de UN Inventario
// ====================================

obtenerInventario(xId: number, xNumero: string = '0/0'){
  let url = this.usuarioService.url + `/Get_Registro/IF/${xId}/false/${xNumero}`;
  url += '?token=' + this.usuarioService.token;

  return this.http.get(url).pipe(
    catchError( err => {
      this.usuarioService.gestionaError(err);
      return throwError(err);
  }));
}

// ====================================
// Buscar Artículo Bipado
// ====================================

buscarArticulos(xArticulo: any){
  let url = this.usuarioService.url + '/LineaInventario/';
  url += '?token=' + this.usuarioService.token;

  const xArticuloJSON = JSON.stringify(xArticulo);

  console.log('flag' + xArticuloJSON, url);
  return this.http.post( url, xArticuloJSON )
                  .pipe( map( (resp: any) => {
                    return resp;
                  }),
                  catchError( err => {
                    this.usuarioService.gestionaError(err);
                    return throwError(err);
                  })
                  );
}

// ====================================
// Modificar Artículo Bipado (Modal)
// ====================================

modificarArticulos(xArticuloModificado: any){
  let url = this.usuarioService.url + '/LineaInventario/';
  url += '?token=' + this.usuarioService.token;

  const xArticuloModificadoJSON = JSON.stringify(xArticuloModificado);

  console.log('flagPutModal' + xArticuloModificadoJSON, url);
  return this.http.put( url, xArticuloModificadoJSON )
                  .pipe( map( (resp: any) => {
                    return resp;
                  }),
                  catchError( err => {
                    this.usuarioService.gestionaError(err);
                    return throwError(err);
                  })
                  );
}

// ====================================
// Borrar Artículo Seleccionado
// ====================================

borrarArticulo(xLineaId: number){
  let url = this.usuarioService.url + '/LineaInventario/' + xLineaId;
  url += '?token=' + this.usuarioService.token;

  console.log('flag delete');

  return this.http.delete( url )
                  .pipe( map( (resp: any) => {
                    Swal.fire(
                      'Eliminado',
                      'Línea eliminada correctamente',
                      'success'
                    );
                    console.log(resp);
                  }),
                  catchError( err => {
                    this.usuarioService.gestionaError(err);
                    return throwError(err);
                  }));
}

// ====================================
// Crar nuevo inventario (Pasamos id almacén)
// ====================================

crearInventario(xIdAlmacen: number){
  let url = this.usuarioService.url + '/inventario/';
  url += '?token=' + this.usuarioService.token;

  const xIdAlmacenJSON = JSON.stringify(xIdAlmacen);

  console.log('flag' + xIdAlmacenJSON, url);
  return this.http.post( url, xIdAlmacenJSON )
                  .pipe( map( (resp: any) => {
                    return resp;
                  }),
                  catchError( err => {
                    this.usuarioService.gestionaError(err);
                    return throwError(err);
                  })
                  );
}




// ===========================================================================
// Guardar Storage Inventario
// ===========================================================================

guardarStorage( XalmacenSeleccionado: any  ){

  // Grabamos en el local storage
  // localStorage.setItem('usuario', usuario);
  localStorage.setItem('almacenSeleccionado', JSON.stringify( XalmacenSeleccionado ));

  this.almacenSeleccionado = XalmacenSeleccionado;

}

// ===========================================================================
// Cargar Storage Inventario
// ===========================================================================

cargarStorage( ){

  if ( localStorage.getItem('token')){
    this.almacenSeleccionado =  JSON.parse(localStorage.getItem('almacenSeleccionado'));
  } else {
    this.almacenSeleccionado = null;
  }
}

}
