import { Injectable } from '@angular/core';
import { Usuario } from '../../models/usuario.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { map, catchError, repeat } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SubirArchivoService } from '../subir-archivo/subir-archivo.service';
import { Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuario: Usuario;
  token: string;
  menu: any[] = [];
  url: string;
  bbdd: string;


  constructor(public http: HttpClient, public router: Router, public subirArchivoService: SubirArchivoService) {
    this.cargarStorage();
   }

// ====================================
// Renueva token
// ====================================

renuevaToken() {
  let url = URL_SERVICIOS + '/RefreshToken/';
  url += '?token=' + this.token;
  console.log(url);
  console.log(this.token);
  return this.http.get(url)
                  .pipe( map( (resp: any) => {
                    console.log(resp);
                    this.token = resp.datos.token;
                    localStorage.setItem('token', this.token);
                    // Impresión token
                    console.log(this.token);
                    return true;
                  }),
                  catchError( err => {
                    this.gestionaError(err);
                    return throwError(err);
                  })
                );
}



  // Guards
  estaLogueado(){
    return ( this.token.length > 5 ) ? true : false;
  }

  cargarStorage(){
    if ( localStorage.getItem('token')){
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
      this.menu = JSON.parse(localStorage.getItem('menu'));
      this.bbdd = localStorage.getItem('bbdd');
      this.url = localStorage.getItem('url');
      console.log(this.menu);
    } else {
      this.token = '';
      this.usuario = null;
      this.menu = [];
      this.bbdd = '';
      this.url = '';
    }
  }

  guardarStorage(usuario: Usuario, token: string, menu: any, url: string, bbdd: string ){

     // Grabamos en el local storage
     // localStorage.setItem('usuario', usuario);
     localStorage.setItem('usuario', JSON.stringify( usuario ));
     localStorage.setItem('menu', JSON.stringify( menu ));
     localStorage.setItem('token', token );
     localStorage.setItem('url', url );
     localStorage.setItem('bbdd', bbdd );

     this.usuario = usuario;
     this.token = token;
     this.menu = menu;
     this.url = url;
     this.bbdd = bbdd;

  }

  logout(){
    this.usuario = null;
    this.token = '';
    this.menu = [];
    this.url = '';
    this.bbdd = '';

    // Inventario
    localStorage.removeItem('almacenSeleccionado');

    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('menu');
    localStorage.removeItem('url');
    localStorage.removeItem('bbdd');

    this.router.navigate(['/login/cixpanel']);
  }

// ===============================================
// Login
// ===============================================

  login( usuario: any, recordar: boolean = false){

    if ( recordar ) {
      localStorage.setItem('email', usuario.mail);
    } else {
      localStorage.removeItem('email');
    }

    const url = URL_SERVICIOS + '/usuario';

    const usuarioJSON = JSON.stringify(usuario);

    console.log(usuarioJSON, url);
    return this.http.post( url, usuarioJSON )
                    .pipe(
                      map( (resp: any) => {
                      console.log(resp);
                    // Guardamos la información en el local storage.
                      this.guardarStorage( resp.datos, resp.token, resp.datos.menu.menu, resp.datos.url, resp.datos.bbdd );
                    // Enviamos un true para informar de que se hizo el login.
                      return true;
                    }),
                      catchError( (err: any) => {
                        this.gestionaError(err);
                        return throwError(err);
                    }));

  }

  modificaPerfil( usuario: Usuario) {
    let url = URL_SERVICIOS + '/usuariow/';
    url += '?token=' + this.token;

    const usuarioJSON = usuario;
    const usuarioParse = JSON.parse(localStorage.usuario);

    console.log('usuarioJSON, url');
    console.log(usuarioJSON, url);
    return this.http.put( url, usuarioJSON )
                  .pipe( map( (resp: any) => {
                    console.log(resp);
                    // Modificamos el usuario
                    this.usuario.nombre = resp.datos.nombre;
                    console.log('this.usuario.nombre');
                    console.log(this.usuario.nombre);
                    // Guardamos la información en el local storage.
                    usuarioParse.nombre = resp.datos.nombre;
                    localStorage.removeItem('usuario');
                    localStorage.setItem('usuario', JSON.stringify( usuarioParse ));
                    // Enviamos un Swal para informar de que se hizo la actualización.
                    Swal.fire(
                      'Perfil Actualizado',
                      'Tu Perfil se ha actualizado correctamente',
                      'success'
                    );
                    return resp;
                  }),
                  catchError( err => {
                    this.gestionaError(err);
                    return throwError(err);
                  })
                  );

  }


// ====================================
// Crear Usuarios
// ====================================


crearUsuario( usuario: Usuario ){

  if (!usuario.id){

    let url = URL_SERVICIOS + '/usuariow/';
    url += '?token=' + this.token;

    const usuarioJSON = usuario;

    console.log('flag' + usuarioJSON, url);
    return this.http.post( url, usuarioJSON )
                    .pipe( map( (resp: any) => {
                       // Enviamos un Swal para informar de que se hizo la creación.
                      Swal.fire(
                        'Creado',
                        'Usuario creado correctamente',
                        'success'
                      );
                      return resp;
                    }));

  }else{

    let url = URL_SERVICIOS + '/usuariow/';
    url += '?token=' + this.token;

    const usuarioJSON = usuario;

    console.log('usuarioJSON, url');
    console.log(usuarioJSON, url);
    return this.http.put( url, usuarioJSON )
                  .pipe( map( (resp: any) => {
                    console.log(resp);
                  // Enviamos un Swal para informar de que se hizo la actualización.
                    Swal.fire(
                      'Actualizado',
                      'Usuario actualizado correctamente',
                      'success'
                    );
                    return resp;
                  }));

  }

  }

cambiarImagen( archivo: File, id: string) {

}

// ====================================
// Pintar Usuarios
// ====================================

cargarUsuarios( xUsuario: Usuario, xPagina: number = 0) {
  let url = URL_SERVICIOS + '/Get_lista/US/10/' + xPagina + '/cliente=' + xUsuario.cliente;
  url += '?token=' + this.token;

  return this.http.get(url);
}

// ====================================
// Buscar Usuarios
// ====================================

buscarUsuarios(termino: string) {

  let url = URL_SERVICIOS + '/Get_Busqueda/US/0/0/cliente=' + this.usuario.cliente + '/' + termino;
  url += '?token=' + this.token;

  console.log(url);

  return this.http.get(url)
                  .pipe( map( (resp: any) => {

                    console.log(resp.UsuarioWeb);

                    return resp.UsuarioWeb;

                  }) );

}

// ====================================
// Borrar Usuarios
// ====================================

borrarUsuario(id: string){

  let url = URL_SERVICIOS + '/usuariow/' + id;
  url += '?token=' + this.token;
  console.log(url);

  return this.http.delete(url).pipe( map((resp: any) => {
    Swal.fire(
      'Eliminado',
      'Usuario eliminado correctamente',
      'success'
    );
    console.log(resp);
  }));
}

// ====================================
// Obtener registro
// ====================================

  obtenerUsuario(id: string){

  let url = URL_SERVICIOS + '/Get_Registro/US/' + id + '/true/';
  url += '?token=' + this.token;
  console.log(url);

  return this.http.get(url).pipe( map((resp: any) => {

    console.log(resp);
    return resp;

  }));

}

// ====================================
// GESTIONAR ERROR
// ====================================

gestionaError(xErr: any) {

  // Unautorized
  if (xErr.status === 401){

    console.log(xErr.error.error);
    Swal.fire(
    'Ups!',
    'Sesión caducada por inactividad',
    'error'
  );

    this.router.navigate(['/login']);

  }else{

  console.log(xErr.error.error);
  Swal.fire(
    'Ups!',
    xErr.error.error,
    'error'
  );
}
}

// ====================================
// Transforma fecha SIN Hora
// ====================================

transformaFechaSinHora(xFecha: any){

let fecha: string;

// Año
const ano: any = xFecha.substring(0, 4);
console.log('ano');
console.log(ano);

// Mes
const mes: any = xFecha.substring(5, 7);
console.log('mes');
console.log(mes);

// Dia
const dia: any = xFecha.substring(8, 10);
console.log('dia');
console.log(dia);

// Crear fecha

fecha = `${dia}-${mes}-${ano}`;

return fecha;

}

}
