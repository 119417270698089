import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {

  // Propiedades

  titulo: string;

  constructor( private router: Router ) {

    this.router.events.pipe(

      filter( xEvent => xEvent instanceof ActivationEnd ),
      filter( (xEvent: ActivationEnd)  => xEvent.snapshot.firstChild === null ),
      map( (xEvent: ActivationEnd ) => xEvent.snapshot.data )
    )

    .subscribe( xData => {

      console.log(xData);
      this.titulo = xData.titulo;

    });
  }

  ngOnInit(): void {
  }

}
