<!-- Header -->


<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-6 ribbon-wrapper">
                        <div *ngIf="historiaSeleccionada.identificador" class="ribbon ribbon-bookmark  ribbon-info">
                            Nº &nbsp; {{historiaSeleccionada.identificador}}
                        </div>
                        <div *ngIf="!historiaSeleccionada.identificador" class="ribbon ribbon-bookmark  ribbon-info">
                            Nuevo cambio de precios
                        </div>
                    </div>
                    <div *ngIf="historiaSeleccionada.identificador" class="text-right col-6 d-none d-md-inline">
                        <button (click)="reload()" type="button" class="btn waves-effect waves-light btn-rounded btn-warning"><i class="mdi mdi-creation"></i>
                          Nuevo
                         </button>
                    </div>
                </div>
                <h3 class="card-title"><i class="mdi mdi-gas-station"></i> Estaciones disponibles</h3>
                <div class="row d-flex justify-content-end">
                    <span style="margin-right: 2em;" *ngIf="historiaSeleccionada.aplicado === 1" class="badge badge-info"><i class=" mdi mdi-check-circle-outline"></i> Aplicado</span>

                    <div *ngIf="!historiaSeleccionada.identificador" class="col-lg-3 col-md-12 col-sm-12 mb-2 input-group">
                        <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                        <input [(ngModel)]="fechaSeleccionada" name="fecha" type="date" class="form-control" placeholder="dd/mm/yyyy" (keydown)="$event.preventDefault();">
                    </div>

                    <!-- <dp-date-picker *ngIf="!historiaSeleccionada.identificador" placeholder="Selecciona fecha y hora..." theme="dp-material" mode="daytime" [(ngModel)]="selectedDate" [config]="datePickerConfig"></dp-date-picker> -->
                    <div *ngIf="!historiaSeleccionada.identificador" class="col-lg-2 col-md-12 col-sm-12 mb-2 input-group">
                        <span class="input-group-addon"><i class="mdi mdi-clock"></i></span>
                        <input [(ngModel)]="horaSeleccionada" name="hora" type="time" class="form-control">
                    </div>

                    <div *ngIf="historiaSeleccionada.identificador" class="col-lg-4 col-md-12 col-sm-12 mb-2 d-flex justify-content-end">
                        <span *ngIf="historiaSeleccionada.identificador"><i class="mdi mdi-calendar-clock"></i>&times; <b>{{historiaSeleccionada.fecha_aplicacion}}</b></span>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 mb-2 d-flex justify-content-end">
                        <button (click)="obtenerHistorial()" type="button" class="ml-5 btn waves-effect waves-light btn-rounded btn-info d-none d-md-inline"><i class="mdi mdi-newspaper"></i>
                        Historial
                    </button>
                        <button *ngIf="!historiaSeleccionada.identificador" type="button" class="ml-2 btn waves-effect waves-light btn-rounded btn-primary d-none d-md-inline" [disabled]="fechaSeleccionada === undefined || horaSeleccionada === undefined " (click)="guardaryenviar()">
                    Enviar <i class="mdi mdi-send"></i>
                    </button>
                        <button *ngIf="historiaSeleccionada.identificador" type="button" class=" ml-2 btn waves-effect waves-light btn-rounded btn-danger d-none d-md-inline" (click)="borrarHistoria()">
                        <i class="mdi mdi-delete"></i> Borrar
                    </button>
                    </div>
                </div>


                <!-- Loading -->

                <div class="row animated fadeIn" *ngIf="cargando">
                    <div class="col-sm-12">
                        <div class="alert alert-warning text-center">
                            <strong> Cargando</strong>
                            <br>
                            <i class="fa fa-refresh fa-spin fa-2x"></i>
                        </div>
                    </div>
                </div>
                <!-- Final Loading -->

                <table class="table table-hover" #table [ngClass]="{'table-responsive' : table.clientWidth < 600}">
                    <thead>
                        <tr>
                            <th>Carburante</th>
                            <th>Precio</th>
                            <th *ngFor="let estacion of estaciones">{{estacion.codigo}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let precioTabla of preciosTabla">
                            <td class="table-active">
                                {{precioTabla.desc_reducida}}
                            </td>
                            <td>
                                {{precioTabla.precio}}
                            </td>
                            <td *ngFor="let estacion of precioTabla.estaciones">
                                <i *ngIf="true === estacion.seleccionado" class="mdi mdi-check"></i>
                            </td>
                            <td>
                                <button *ngIf="!historiaSeleccionada.identificador" (click)="modificarCarburante(precioTabla)" type="button" class="btn btn-primary"><i class="mdi mdi-lead-pencil"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- FAB button Identificador -->
        <div class="d-block d-md-none" *ngIf="historiaSeleccionada.identificador">
            <button class="fab btn waves-effect waves-light btn-rounded btn-primary" [ngClass]="{'fab-active' : fab == true}" (click)="fabChange()">
                    <i style="color: white" class="mdi mdi-close"></i>
                </button>
            <div class="box" [ngClass]="{'box-active3' : fab == true}" (click)="fabChange()">
                <button (click)="reload()" type="button" class="item item1 btn waves-effect waves-light btn-rounded btn-warning"><i class="mdi mdi-creation"></i>
                </button>
                <button (click)="obtenerHistorial()" type="button" class="item item2 btn waves-effect waves-light btn-rounded btn-info"><i class="mdi mdi-newspaper"></i>
                </button>
                <button type="button" class="item item99 btn waves-effect waves-light btn-rounded btn-danger" (click)="borrarHistoria()">
                    <i class="mdi mdi-delete"></i>
                </button>
            </div>
        </div>
        <!-- FAB button !Identificador -->
        <div class="d-block d-md-none" *ngIf="!historiaSeleccionada.identificador">
            <button class="fab btn waves-effect waves-light btn-rounded btn-primary" [ngClass]="{'fab-active' : fab == true}" (click)="fabChange()">
                            <i style="color: white" class="mdi mdi-close"></i>
                        </button>
            <div class="box" [ngClass]="{'box-active2' : fab == true}" (click)="fabChange()">
                <button (click)="obtenerHistorial()" type="button" class=" item item1 btn waves-effect waves-light btn-rounded btn-info"><i class="mdi mdi-newspaper"></i>
                        </button>
                <button type="button" class="item item99 btn waves-effect waves-light btn-rounded btn-primary" [disabled]="fechaSeleccionada === undefined || horaSeleccionada === undefined " (click)="guardaryenviar()">
                        <i class="mdi mdi-send"></i>
                </button>
            </div>
        </div>

    </div>
</div>
<!-- Modal 1 -->
<div class="modal animated fadeIn show" tabindex="-1" role="dialog" style="display: block;" *ngIf="modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <form #f="ngForm" ngNativeValidate (ngSubmit)="guardar(f)" class="form p-t-20">
                <div class="modal-body">
                    <div>
                        <div>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="modificarModal()">
                    <span aria-hidden="true">&times;</span>
                    </button>
                        </div>
                    </div>
                    <h3 class="m-b-20 text-center"><b>Informe precio del producto</b></h3>
                    <h4 class="m-b-20 text-center"><b>{{carburanteSeleccionado.descripcion}}</b></h4>
                    <!-- Fin Implementación de selector de opciones -->
                    <!-- Implementación de selector de opciones -->
                    <label>Estaciones</label>
                    <ng-multiselect-dropdown [placeholder]="'Selecciona las estaciones...'" [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="Options" name="Options" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <span *ngIf="Options.length === 0" class="text-danger"><i class="mdi mdi-exclamation"></i><small> Tiene que informar una estación</small></span>
                    <br>
                    <!-- Fin Implementación de selector de opciones -->
                    <label>Precio</label>
                    <input name="precio" min="0" [(ngModel)]="precio" type="number" class="form-control" step="0.001" required>
                    <span *ngIf="precio === null" class="text-danger"><i class="mdi mdi-exclamation"></i><small> Tiene que informar un precio</small></span>
                </div>
                <div class="modal-footer d-flex">
                    <button type="button" (click)="guardar(f, true)" class="btn btn-danger waves-effect waves-light "><i class="mdi mdi-delete"></i></button>
                    <button type="submit" [disabled]="precio === null || Options.length === 0 " class="btn btn-primary waves-effect waves-light "><i class="mdi mdi-content-save"></i> Guardar precio</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal 2 -->
<div class="modal animated fadeIn show" tabindex="-1" role="dialog" style="display: block;" *ngIf="modal2">
    <div class="modal-dialog" style="overflow-y: initial !important">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="text-center mt-1"><i class="mdi mdi-newspaper"></i> <b> Historial de cambios</b></h3>
            </div>
            <div class="modal-body" style="max-height: calc(100vh - 200px); overflow-y: auto;">
                <div>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Fecha aplicación</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let historia of historial" [ngClass]="{'table-info' : historia.aplicado === 1}">
                                <td>
                                    {{historia.identificador}}
                                </td>
                                <td>
                                    {{historia.fecha_aplicacion}}
                                </td>
                                <td>
                                    <button [routerLink]="['/modificaPrecios',historia.identificador]" type="button" class="btn btn-primary"><i class="fa fa-eye"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer d-flex">
                <button *ngIf="!cargandoMas" (click)="cargarMas()" [disabled]="cargaHistorial > totalRegistrosHistorial" type="button" class="btn btn-info waves-effect waves-light "><i class="mdi mdi-cloud-download"></i> Cargar mas</button>
                <button *ngIf="cargandoMas" type="button" class="disabled btn btn-info waves-effect waves-light "><i class="fa fa-refresh fa-spin"></i> Cargar mas</button>
                <button (click)="modificarModal2()" type="button" class="btn btn-danger waves-effect waves-light "><i class="mdi mdi-close"></i> Cerrar</button>
            </div>
        </div>
    </div>
</div>