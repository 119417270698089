import { Component } from '@angular/core';
import { SettingsService } from './services/settings/settings.service';
import { SWorkerAndNotificationService } from './services/sWorkerAndNotification/s-worker-and-notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'CixPanel';

  // Aquí van las funciones que queremos que se inicializen al cargar la app

  constructor( public sAjustes: SettingsService, public sWorkerAndNotificationService: SWorkerAndNotificationService) {
    sWorkerAndNotificationService.InstalarServiceWorker();
  }

}
