// Modelo, plantillas de nuestro usuarios
// El orden de las propiedades es inportante.
// Deben coincidir el nombre y valor de las propiedades con el backend

export class Carburante {

    constructor(
        public codigo?: string,
        public descripcion?: string,
        public desc_reducida?: string,
        public clave?: string,
        public orden?: string,
        public rn?: number,
    ){

    }
}
