<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Wallpaper Beta si es Demo  -->
<!-- ============================================================== -->
<section id="wrapper" #section class="login-register login-sidebar" [ngStyle]="{'background-image': section.clientWidth < 600 ? 'url(../assets/images/background/login-register-movil.jpg)' : 'url(../assets/images/background/login-register.jpg)' }">
    <div class="login-box card">
        <div class="card-body">
            <!-- Al pulsar el botón submit dispara la función ingresar -->
            <form ngNativeValidate #f="ngForm" class="form-horizontal form-material" (ngSubmit)="ingresar( f )">
                <a href="javascript:void(0)" class="text-center db"><img src="../assets/images/users/{{logo}}.png" alt="Logo" /><br/><img src="../assets/images/users/logo-text.png" alt="Home" /></a>
                <div class="form-group m-t-40">
                    <div class="col-xs-12">
                        <input [(ngModel)]="mail" name="mail" class="form-control" type="email" required placeholder="Email">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input ngModel name="password" class="form-control" type="password" required placeholder="Contraseña">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary pull-left p-t-0">
                            <input [(ngModel)]="recuerdame" name="recuerdame" id="checkbox-signup" type="checkbox" class="filled-in chk-col-light-blue">
                            <label for="checkbox-signup"> Recuérdame </label>
                        </div>
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Iniciar sesión</button>
                    </div>
                </div>
            </form>
            <form class="form-horizontal" id="recoverform" action="index.html">
                <div class="form-group ">
                    <div class="col-xs-12">
                        <h3>Recuperar contraseña</h3>
                        <p class="text-muted">Dejanos tu email para recuperar la constraña</p>
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" required="" placeholder="Email">
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->