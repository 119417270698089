import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UsuarioService } from '../services/usuario/usuario.service';
import { Usuario } from '../models/usuario.model';


// TEST
import { ActivatedRoute } from '@angular/router';

// Función del login, inicia los plugins del template.
declare function init_plugins();
// google
declare const gapi: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  mail: string;
  recuerdame: boolean = false;

  auth2: any;


  // TEST: Parámetro login

  logo: string = 'logo';

  constructor( public sRouter: Router,
               public usuarioService: UsuarioService,
               public activatedroute: ActivatedRoute) {
                 // TEST
                 this.activatedroute.params.subscribe( resp => {

                  const id = resp.id;

                  if ( id !== 'cixpanel') {
                      this.logo = id;
                      console.log('Passa por el ID con string');
                      console.log(id);
                  }else{
                      return;
                  }
                });
               }

  ngOnInit(): void {
    init_plugins();

    // El capo email tiene el valor del localstorage, si es undefined lo deja en blanco.
    this.mail = localStorage.getItem('email') || '';
    // Si hay valor, deja puesto el check
    if (this.mail.length > 1) {
      this.recuerdame = true;
    }
  }

  ingresar( f: NgForm ) {

    if ( f.invalid ){
      return;
    }

    const usuario = new Usuario(f.value.mail, f.value.password);

    this.usuarioService.login( usuario, this.recuerdame )
                       .subscribe( resp => {
                          this.sRouter.navigate(['/dashboard']);
                       });

    console.log(f.valid);
    console.log(f.value);

  }

}
