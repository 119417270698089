import { Injectable } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, repeat } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModificaPreciosService {


  constructor(
    public usuarioService: UsuarioService, public http: HttpClient
  ) { }


// ====================================
// Obtiene la lista de carburantes que seleccionaremos para el cambio de estación.
// ====================================

obtenerCarburantes(){
  let url = this.usuarioService.url + '/Get_lista/CA/';
  url += '?token=' + this.usuarioService.token;

  return this.http.get(url).pipe(
    catchError( err => {
      this.usuarioService.gestionaError(err);
      return throwError(err);
  }));
}

// ====================================
// Obtiene la lista de historial
// ====================================

obtenerHistorial(xNumero = '0/0'){
  let url = this.usuarioService.url + `/Get_Lista/CP/${xNumero}`;
  url += '?token=' + this.usuarioService.token;

  console.log('urlDashboard');
  console.log(url);

  return this.http.get(url).pipe(
    catchError( err => {
      this.usuarioService.gestionaError(err);
      return throwError(err);
  }));
}

// ====================================
// Obtiene la lista de historial
// ====================================

abrirHistoria(xId: number){
  let url = this.usuarioService.url + `/Get_Registro/CP/${xId}/`;
  url += '?token=' + this.usuarioService.token;

  return this.http.get(url).pipe(
    catchError( err => {
      this.usuarioService.gestionaError(err);
      return throwError(err);
  }));
}


// ====================================
// Enviar nuevo cambio de precios (Pasamos objetoenvio al pulsar guardar y enviar)
// ====================================

crearCambioPrecio(xIdmolde: object){
  let url = this.usuarioService.url + '/CambioPrecio/';
  url += '?token=' + this.usuarioService.token;

  const xIdmoldeJSON = xIdmolde;

  console.log('flag' + xIdmoldeJSON, url);
  return this.http.post( url, xIdmoldeJSON )
                  .pipe( map( (resp: any) => {
                    console.log('resp');
                    console.log(resp);
                    return resp;
                  }),
                  catchError( err => {
                    this.usuarioService.gestionaError(err);
                    return throwError(err);
                  })
                  );
}

// ====================================
// Delete: Cambio de precios
// ====================================

borrarCambioPrecio(xId: number){
  let url = this.usuarioService.url + `/CambioPrecio/${xId}/`;
  url += '?token=' + this.usuarioService.token;

  return this.http.delete(url).pipe(
    catchError( err => {
      this.usuarioService.gestionaError(err);
      return throwError(err);
  }));
}





}


