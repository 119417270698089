import { Component, OnInit } from '@angular/core';
import { ResultadosService } from '../../services/resultados/resultados.service';
import { Graficageneral } from '../../models/graficaGeneral.model';
import { GraficoLineComponent } from '../../components/grafico-line/grafico-line.component';
import { UsuarioService } from '../../services/usuario/usuario.service';

@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.css']
})
export class ResultadosComponent implements OnInit {

  /* GRAFICA 1: General*/
  loadingGrafica1: boolean = false;
  // tslint:disable-next-line: max-line-length
  graficaGeneral: Graficageneral = {labels: [''], datasets: [{data: [], label: ''}, {data: [], label: ''}, {data: [], label: ''}, {data: [], label: ''}, ], type: 'line', leyenda: 'Leyenda'};

  /* GRAFICA 2: Carburantes */
  loadingGrafica2: boolean = false;
  fechaInicial2: any = undefined;
  fechaFinal2: any = undefined;
  // tslint:disable-next-line: max-line-length
  graficaGeneral2: Graficageneral = {labels: [''], datasets: [{data: [], label: ''}, {data: [], label: ''}, {data: [], label: ''}, {data: [], label: ''}, ], type: 'line', leyenda: 'Leyenda'};
  InfoGrafica2: boolean = true;

  constructor(
    public resultadoService: ResultadosService,
    public graficoLineComponent: GraficoLineComponent,
    public usuarioService: UsuarioService
    ) {

  }

  ngOnInit(): void {
    this.obtenerGraficaGeneral();
    console.log('this.graficaGeneralInit');
    console.log(this.graficaGeneral);
  }

  obtenerGraficaGeneral(){
    this.resultadoService.cargarGraficaGeneral()
                          .subscribe((resp: any) => {
                             this.graficaGeneral = resp;
                             this.loadingGrafica1 = true;

    });

  }
  obtenerGraficaVentaPeriodoCarburante(){
    this.loadingGrafica2 = true;
    // Transformar fecha sin hora antes de enviar
    const fechaEnvioInicial = this.usuarioService.transformaFechaSinHora(this.fechaInicial2);
    const fechaEnvioFinal = this.usuarioService.transformaFechaSinHora(this.fechaFinal2);

    console.log('this.fechaInicial2');
    console.log(this.fechaInicial2);
    console.log('this.fechaFinal2');
    console.log(this.fechaFinal2);


    this.resultadoService.graficaVentaPeriodoCarburante(fechaEnvioInicial, fechaEnvioFinal)
                          .subscribe((resp: any) => {
                             this.graficaGeneral2 = resp;
                             this.loadingGrafica2 = false;
                             this.InfoGrafica2 = false;

    });

  }

}
