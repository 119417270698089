// Importamos el Módulo de rutas y el modelo que necesitamos
// para nuestro arreglo

import { RouterModule, Routes } from '@angular/router';

// Importamos las diferentes paginas TS de nuestra aplciación. las clases

// Tenemos todo el HTML que no es el login en el PagesComponent
import { PagesComponent } from './pages/pages.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ProgressComponent } from './pages/progress/progress.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';


const appRoutes: Routes = [

    /*Tenemos dos router-outlet, uno para el login y otro para las otras páginas*/

    { path: '', component: PagesComponent,
    // Rutas hijas cuando no ponemos nada en la url.
    children: [
        { path: 'dashboard', component: DashboardComponent},
        { path: 'progress', component: ProgressComponent},
        // Si la url està vacia, nos lleva al dashboard
        { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
    ]},
    // Rutas al poner login o register en la url  /*router-outlet a parte*/
    { path: 'login/:id', component: LoginComponent},
    // Si hay algo que no existe en la url, redireciona al 404
    { path: '**', component: NopagefoundComponent},
];

// Creamos una constante que usaremos para exportar nuestras rutass
// Le decimos al RouterModule que lea el array que acabamos de crear
// y que los transforme en rutas que el navegador entienda.
// También le decimos que use el hash # en nuestras rutas.
export const APP_ROUTES = RouterModule.forRoot(appRoutes, {useHash: true});
