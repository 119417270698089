<div class="row">
    <div class="col-md-6" *ngIf="urlPinarCards.includes('/inventarios')">
        <div class="card card-outline-info">
            <div class="card-header">
                <h4 class="m-b-0 text-white">Inventarios</h4>
            </div>
            <div class="card-body">
                <h3 class="card-title">Últimos inventarios</h3>
                <h6 *ngIf="almacenSeleccionado === null" class="card-title">{{descripcion}}</h6>
                <!--AVISO Si no hay almacen se ve -->
                <div class="row animated fadeIn mt-3" *ngIf="almacenSeleccionado === null">
                    <div class="col-sm-12">
                        <div class="alert alert-danger text-center">
                            <strong>No hay inventarios recientes.</strong>
                            <br>
                            <i class="mdi mdi-radio-tower fa-2x"></i>
                        </div>
                    </div>
                </div>
                <!-- TABLA Si no hay almacen no se ve -->
                <table *ngIf="almacenSeleccionado !== null" class="table table-hover" #table [ngClass]="{'table-responsive' : table.clientWidth < 500}">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha</th>
                            <th>Registros</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let inventario of inventarios">
                            <td>
                                {{inventario.identificador}}
                            </td>
                            <td>
                                {{inventario.fecha_inventario}}
                            </td>
                            <td>
                                {{inventario.nregistros}}
                            </td>
                            <td>
                                <button [routerLink]="['/inventario',inventario.identificador]" class="btn btn-primary">
                                <i class="mdi mdi-lead-pencil "></i>
                            </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button [routerLink]="['/inventarios']" type="button" class="btn waves-effect waves-light btn-rounded btn-info">
                    Inventarios <i class="mdi mdi-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-outline-info" *ngIf="urlPinarCards.includes('/modificaPrecios/nuevo')">
            <div class="card-header">
                <h4 class="m-b-0 text-white">Precios estaciones</h4>
            </div>
            <div class="card-body">
                <h3 class="card-title">Próximos cambios de precio</h3>
                <!-- TABLA Si no hay almacen no se ve -->
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha aplicación</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let historia of historial" [ngClass]="{'table-info' : historia.aplicado === 1}">
                            <td>
                                {{historia.identificador}}
                            </td>
                            <td>
                                {{historia.fecha_aplicacion}}
                            </td>
                            <td>
                                <button [routerLink]="['/modificaPrecios',historia.identificador]" class="btn btn-primary">
                                <i class="mdi mdi-lead-pencil "></i>
                            </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button [routerLink]="['/modificaPrecios/nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-info">
                    Precios estaciones <i class="mdi mdi-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-6" *ngIf="urlPinarCards.includes('/ventas')">
        <div class="card card-outline-info">
            <div class="card-header">
                <h4 class="m-b-0 text-white">Ventas</h4>
            </div>
            <div class="card-body">
                <h3 class="card-title">Ventas de esta semana</h3>
                <button [routerLink]="['/inventarios']" type="button" class="btn waves-effect waves-light btn-rounded btn-danger">
                    Ventas <i class="mdi mdi-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</div>