<div class="row animated fadeIn">
    <div class="col-lg-7">
        <div #body class="card">
            <div class="card-body">
                <h4 class="card-title"><i class="mdi mdi-account"></i> Usuario {{usuariow.nombre}}</h4>
                <h6 class="card-subtitle">Ficha con los datos del usuario</h6>

                <form #f="ngForm" ngNativeValidate (ngSubmit)="guardar(f)" class="form p-t-20">
                    <div class="form-group">
                        <label for="exampleInputuname">Nombre</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="mdi mdi-account"></i></div>
                            <input [(ngModel)]="usuariow.nombre" name="nombre" type="text" class="form-control" placeholder="nombre" required>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Email</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="mdi mdi-email"></i></div>
                            <input [(ngModel)]="usuariow.mail" name="mail" type="email" class="form-control" [disabled]="usuariow.rol === 'ADMIN'" placeholder="mail" required>
                        </div>
                    </div>
                    <div class="dropdown-divider"></div>
                    <!-- Implementación de selector de opciones -->
                    <label>Módulos</label>
                    <ng-multiselect-dropdown [placeholder]="'Selecciona los módulos del usuario...'" [disabled]="usuariow.rol === 'ADMIN'" [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="Options" name="Options" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <!-- Fin Implementación de selector de opciones -->
                    <div class="dropdown-divider"></div>
                    <!-- Implementación Modificacion de contraseñas -->
                    <div class="form-group">
                        <label>Constraseña</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="mdi mdi-key"></i></div>
                            <input [ngModel]="usuariow.password" name="password" class="form-control" type="password" placeholder="Constraseña" [required]="!usuariow.id">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Repetir contraseña</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="mdi mdi-key-change"></i></div>
                            <input [ngModel]="usuariow.password" name="password2" class="form-control" type="password" placeholder="Repetir contraseña" [required]="!usuariow.id">
                        </div>
                    </div>
                    <div class="form-group" *ngIf="f.value.password !== f.value.password2 && !f.pristine">
                        <div class="col-xs-12">
                            <div class="alert alert-danger text-center" role="alert">
                                <i class="mdi mdi-exclamation"></i>Las contraseñas no coinciden<i class="mdi mdi-exclamation"></i>
                            </div>
                        </div>
                    </div>
                    <!-- Fin Implementación de contraseñas -->
                    <div class="dropdown-divider"></div>
                    <div class="d-none d-md-block">
                        <a routerLink="/usuarios" class="btn btn-danger waves-effect waves-light m-r-10"><i class="mdi mdi-arrow-left"></i> Cancelar</a>
                        <button type="submit" name="button_desktop" class="btn btn-success waves-effect waves-light m-r-10" [disabled]="f.value.password !== f.value.password2 && !f.pristine"><i class="mdi mdi-content-save"></i> Guardar</button>
                    </div>
                    <!-- FAB button -->
                    <div class="d-block d-md-none">
                        <a class="fab btn waves-effect waves-light btn-rounded btn-primary" [ngClass]="{'fab-active' : fab == true}" (click)="fabChange()">
                            <i style="color: white" class="mdi mdi-close"></i>
                        </a>
                        <div class="box" [ngClass]="{'box-active2' : fab == true}" (click)="fabChange()">
                            <a routerLink="/usuarios" class="btn waves-effect waves-light btn-danger item item1"><i class="mdi mdi-arrow-left"></i></a>
                            <button type="submit" name="button_fab" class="btn waves-effect waves-light btn-success item item99" [disabled]="f.value.password !== f.value.password2 && !f.pristine"><i class="mdi mdi-content-save"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>