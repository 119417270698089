import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { retry } from 'rxjs/operators';


@Component({
  selector: 'app-rxjs',
  templateUrl: './rxjs.component.html',
  styleUrls: ['./rxjs.component.css']
})
export class RxjsComponent implements OnInit, OnDestroy {

  // Para el destroy
  subscription: Subscription;

  constructor( ) {

      // Pipe permite usar una serie de operadores, en este caso usamos el retry.
      this.subscription = this.regresaObservable().pipe(
        retry(2)
      )
      // Devuelve tres callbacks, siempre por este orden: 1.NEXT 2.Error 3.Complete
      .subscribe(
        // Pone el string next y luego lo que recoje el observable.
        numero => console.log('Next', numero),
        // Pone un string y luego el error que da el observable.
        error => console.error('Error en el Observable', error),
        // Tiene un script que se muestra al desconectar el observable.
        () => console.log('El observador terminó!!!')
      );
  }

  ngOnInit(): void {


  }

  ngOnDestroy() {

        this.subscription.unsubscribe();
  }

  regresaObservable(): Observable <any>{

    const obs = new Observable <any>( observer => {

      let contador = 0;

      const intervalo =  setInterval( () => {

        contador += 1;

        // NEXT
        observer.next( contador );

        if (contador === 3 ){
          clearInterval(intervalo);
          observer.complete();
        }
          // ERROR
        /* if (contador === 2 ){
          clearInterval(intervalo);
          observer.error('Error del observable');
        }*/

      }, 1000 );
  });
    return obs;

}

}
