import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SettingsService } from 'src/app/services/settings/settings.service';



@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})

export class AccountSettingsComponent implements OnInit {

  constructor( @Inject(DOCUMENT) private sDocument: Document,
               public sAjustes: SettingsService
              ) { }

  ngOnInit(  ): void {
    this.colocarCheck();
  }

  cambiarColor(xTema: string, xLink: any) {

    this.aplicarCheck(xLink);
    this.sAjustes.aplicarTema(xTema);

  }


  aplicarCheck(xLink?: any) {
  const checkHTML: any = this.sDocument.querySelectorAll('.selector');

  // Borra todos los check
  for (const check of checkHTML) {
    check.classList.remove('working');
  }
  // Añade check al pulsado
  xLink.classList.add('working');

}

  colocarCheck() {

    const checkHTML: any = this.sDocument.querySelectorAll('.selector');

    // Si el tema es igual que el que tenemos guardado, pon un check
    for (const check of checkHTML) {
        if ( check.getAttribute('data-theme') === this.sAjustes.ajustes.tema){
          check.classList.add('working');
        }
    }
  }
}
