// Modelo, plantillas de nuestro usuarios
// El orden de las propiedades es inportante.
// Deben coincidir el nombre y valor de las propiedades con el backend

export class Inventario {

    constructor(
        public identificador?: number,
        // tslint:disable-next-line: variable-name
        public fecha_inventario?: string,
        public almacen?: number,
        // tslint:disable-next-line: variable-name
        public desc_almacen?: string,
        public rn?: number,
        public Lineas?: any[],
        public nregistros?: number
    ){

    }
}

